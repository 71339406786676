import React, { useCallback, useState, useEffect, useRef, } from "react";
import { View, Text, ScrollView, ImageBackground, Modal, Animated, Pressable, Image, Switch, Picker, Dimensions } from "react-native";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import { getFirestore, doc, getDoc, setDoc, updateDoc, query, where, getDocs, collection, writeBatch } from "firebase/firestore";
import axios from "axios";
import { useUser } from "../Widgets/UserContext";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import styles from "../Styles/EventsStyles";
import ImageGallery from "./EventsWidgets/ImageGallery";
import TextBox from "./EventsWidgets/TextBox";
import RSVP from "./EventsWidgets/RSVP";
import PictureFrame from "./EventsWidgets/PictureFrame";
import URLPreview from "./EventsWidgets/URLPreview";
import HeaderBar from "../Widgets/HeaderBar";
import GraduationCard from "../Widgets/GraduationCard";
import CombinedCard from "../Widgets/CombinedCard";
import EventCard from "../Widgets/EventCard"; // Import EventCard
import { BackgroundProvider } from './Helpers/BackgroundContext';
import { debounce } from 'lodash';
import PrivatePageMessage from "./Helpers/PrivatePageMessage"; // Import the new component
import Countdown from "./EventsWidgets/Countdown";
import { buttonHoverStyle, imageHoverStyle } from "../../utils/hoverStyles";
import { TutorialPopup, useTutorial } from "./Helpers/Tutorials";
import { REACT_APP_AWS_SERVER_URL } from '@env';	

let LOCAL_SERVER_URL = "http://localhost:3300/fetch-metadata";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL + "/fetch-metadata";

const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

const Events = ({ route }) => {
	//#region - Variables

	const { width, height } = Dimensions.get('window');
	const isMobile = width < 768;
	const isTablet = width >= 768 && width < 1024;
	const isSmallDesktop = width >= 1024 && width < 1440;
	const isLargeDesktop = width >= 1440;


	const {
		setShowTutorial,
		setActivePopupId,
		showTutorial,
		isLoading,
		tutorialsToShow,
		setClosedTutorials,
		setTutorialsToShow,
		activePopupId
	} = useTutorial();

	const fontFamilies = {
		Bodoni72Smallcaps: "Bodoni72Smallcaps",
	};

	// Initialize Firestore
	const db = getFirestore(app);
	const { currentUser, currentUserData } = useUser();
	const [pageOwner, setPageOwner] = useState(null);
	const navigation = useNavigation();
	const auth = getAuth(app);
	const [pageOwnerId, setPageOwnerId] = useState(null); // Add state for pageOwnerId
	const [isPageOwnerReady, setIsPageOwnerReady] = useState(false);
	const [showLogoutModal, setShowLogoutModal] = React.useState(false);
	const [isOwner, setIsOwner] = useState(false); // New state to handle page ownership
	const [loading, setLoading] = useState(true); // New loading state
	const scrollViewRef = useRef(null);
	const [hasChanges, setHasChanges] = useState(false); // New state to track changes
	const [isVisitorView, setIsVisitorView] = useState(false); // New state for viewing mode
	const [cardBackground, setCardBackground] = useState(null); // State for card background

	const [loadingMessage, setLoadingMessage] = useState(""); // State for loading message
	const [successMessage, setSuccessMessage] = useState(""); // State for success message
	const [errorMessage, setErrorMessage] = useState(""); // State for error message

	const [alertShown, setAlertShown] = useState(false); // Add state to track alert display

	// Add state for authorization
	const [isAuthorized, setIsAuthorized] = useState(false);
	const [isAuthorizationLoading, setIsAuthorizationLoading] = useState(true); // New state for authorization loading
	const [hoveredButton, setHoveredButton] = useState(null);
	//#endregion

	//#region - Testing Only Development
	const { setCurrentUser, setCurrentUserData } = useUser();

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (authUser) => {
			if (authUser) {
				// Update the user in the context
				setCurrentUser(authUser);
				// Optionally update user data from Firestore
			} else {
				setCurrentUser(null);
				setCurrentUserData(null);
			}
		});

		return () => unsubscribe(); // Cleanup subscription
	}, []);

	useEffect(() => {
		if (isDevelopment) {
			const testLogin = async () => {
				try {
					const testEmails = [
						"Tjhawk27@gmail.com", // Trevor Hawkins
						"trevorhawkinsofficial@gmail.com", // Olivia Nichols
						"trevorhawkins@makenoisemedia.io", // Sam Minix
						"info@dsignable.com" // Tristan
					];
					const password = "Newtjhawk27";
					const lowerCaseEmail = testEmails[3].toLowerCase(); // Default to Jennifer

					const userCredential = await signInWithEmailAndPassword(auth, lowerCaseEmail, password);
					const user = userCredential.user;

					const userDocRef = doc(db, "users", lowerCaseEmail);
					const userDocSnap = await getDoc(userDocRef);

					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						setCurrentUser(user); // Set the auth user
						setCurrentUserData(userData); // Set the Firestore user data
						console.warn('Logged In as ' + lowerCaseEmail);
					} else {
						console.error("No such user data!");
					}
				} catch (error) {
					console.error("Error logging in with test account", error);
				}
			};
			// testLogin();
		}
	}, []);

	useEffect(() => {
		const loadCards = async () => {
			setCards([]);
			setLoading(true); // Set loading to true before starting the load
			setLoadingMessage(""); // Clear any previous loading messages
			try {
				const userEmail = currentUser.email.toLowerCase();
				const userDocRef = doc(db, "users", userEmail);
				const userDoc = await getDoc(userDocRef);

				if (userDoc.exists()) {
					const userData = userDoc.data();
					if (userData.pages && userData.pages.eventsPageConfig && userData.pages.eventsPageConfig.cards) {
						setCards(userData.pages.eventsPageConfig.cards);
						// console.log("Loaded Cards:", userData.pages.eventsPageConfig.cards);
					}
				}
			} catch (error) {
				console.error("Error loading cards:", error);
				setErrorMessage("Failed to load cards. Please try again."); // Set error message
				setTimeout(() => setErrorMessage(""), 2000); // Clear error message after 2 seconds
			} finally {
				setLoading(false); // Set loading to false after cards are loaded or if an error occurs
			}
		};

		loadCards();
	}, [currentUser]);
	//#endregion

	//#region - Page Creation Variables
	const [editMode, setEditMode] = useState(false);
	const [editableMode, setEditableMode] = useState(false); // Control the edit mode
	const [cards, setCards] = useState([]); // State to handle the cards
	const [eventCards, setEventCards] = useState([]); // State to manage the event cards

	// Define saveError state and its setter function
	const [saveError, setSaveError] = useState("");

	// Additional states for background customization
	const [showBackgroundModal, setShowBackgroundModal] = useState(false); // New state for background modal visibility
	const [backgroundImage, setBackgroundImage] = useState(null); // Store the background image
	const [applyBlur, setApplyBlur] = useState(false); // Toggle for blur effect
	const [textColor, setTextColor] = useState("#000000"); // Store the text color
	const predefinedBackgrounds = [require("../assets/Popup Background.png"), require("../assets/Landing Background.png"), require("../assets/Hub Background.png")];
	const [selectedColor, setSelectedColor] = useState("#000000"); // State for the selected color
	const [selectedWidgetType, setSelectedWidgetType] = useState(""); // State for the selected widget type
	const [widgets, setWidgets] = useState([]); // State to handle widgets
	//#endregion

	//#region - Page Creation Functions
	// Function to add a new card
	const addCard = async (type) => {
		const newCard = { id: Date.now().toString(), type, content: "", position: cards.length + 1 };
		console.log("Adding new card:", newCard);

		// Fetch the latest data from the database
		const userEmail = currentUser.email.toLowerCase();
		const userDocRef = doc(db, "users", userEmail);
		const userDocSnap = await getDoc(userDocRef);
		const existingData = userDocSnap.exists() ? userDocSnap.data() : {};
		const existingCards = existingData.pages?.eventsPageConfig?.cards || [];

		// Merge the new card with the existing cards
		const updatedCards = [...existingCards, newCard];
		console.log("Updated Cards after adding new card:", updatedCards);

		// Update the state with the merged data
		setCards(updatedCards);
		setHasChanges(true); // Mark that there are unsaved changes
	};

	// Function to remove a card
	const removeCard = async (id) => {
		// Fetch the latest data from the database
		const userEmail = currentUser.email.toLowerCase();
		const userDocRef = doc(db, "users", userEmail);
		const userDocSnap = await getDoc(userDocRef);
		const existingData = userDocSnap.exists() ? userDocSnap.data() : {};
		const existingCards = existingData.pages?.eventsPageConfig?.cards || [];

		// Filter out the card to be removed
		const updatedCards = existingCards.filter(card => card.id !== id);
		console.log("Updated Cards after removing card:", updatedCards);

		// Update the state with the filtered data
		setCards(updatedCards);
		setHasChanges(true); // Mark that there are unsaved changes
	};

	// Function to handle image upload for ImageGallery
	const handleImageGalleryUpload = async (event, cardIndex) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith("image/")) {
			setLoadingMessage("Uploading image...");
			const storagePath = `widgetsImages/${file.name}-${Date.now()}`;
			const storageRef = ref(getStorage(app), storagePath);

			try {
				const uploadResult = await uploadBytes(storageRef, file);
				const publicUrl = await getDownloadURL(uploadResult.ref);

				// Update the cards state with the new image URL
				const updatedCards = cards.map((card, index) => {
					if (index === cardIndex && card.type === "ImageGallery") {
						return {
							...card,
							content: {
								...card.content,
								images: [...(card.content.images || []), publicUrl],
							},
						};
					}
					return card;
				});

				setCards(updatedCards);

				setHasChanges(true); // Mark that there are unsaved changes
				setLoadingMessage(""); // Clear loading message
				setSuccessMessage("Image uploaded successfully!");
				setTimeout(() => setSuccessMessage(""), 2000); // Clear success message after 2 seconds
			} catch (error) {
				console.error("Failed to upload image:", error);
				setLoadingMessage(""); // Clear loading message
				setErrorMessage("Failed to upload image. Please try again.");
				setTimeout(() => setErrorMessage(""), 2000); // Clear error message after 2 seconds
			}
		} else {
			console.error("No file selected or file is not an image");
		}
	};

	// Function to render each card
	const renderCards = () => {
		return cards.map((card, index) => (
			<EventCard
				key={card.id}
				card={card}
				onRemove={() => removeCard(card.id)}
				onSave={saveWidget} // Pass saveWidget directly
				onAdd={addCard} // Pass the addCard function to EventCard
				isOwner={isOwner && !isVisitorView} // Adjust ownership based on viewing mode
				isLastCard={index === cards.length - 1}
				renderFetchMetadataButton={renderFetchMetadataButton}
				style={styles.eventCard}
				widgetIndex={index} // Pass the index as widgetIndex
				currentUserData={currentUserData}
				pageOwner={pageOwner}
				onImageUpload={(event) => handleImageGalleryUpload(event, index)} // Pass the image upload handler
				onRemoveImage={(imageUrl) => removeImageFromPictureFrame(index, imageUrl)} // Pass the remove function
			/>
		));
	};

	// Debounce the save function
	const debouncedSavePageConfig = debounce(async (cards, currentUser, db) => {
		const userEmail = currentUser.email.toLowerCase();
		const userDocRef = doc(db, "users", userEmail);

		try {
			const batch = writeBatch(db);

			// Fetch existing data22
			const userDocSnap = await getDoc(userDocRef);
			const existingData = userDocSnap.exists() ? userDocSnap.data() : {};

			// Prepare the page configuration
			const pageConfig = {
				eventsPageConfig: {
					cards: cards.map(card => ({
						id: card.id,
						type: card.type,
						content: card.content,
						position: card.position,
						metadata: card.metadata || {},
						images: card.images || [], // Ensure images field is preserved
						customImageUrl: card.customImageUrl || "", // Ensure customImageUrl field is preserved
						rsvp: card.rsvp || "", // Ensure rsvp field is preserved
						url: card.url || "", // Ensure url field is preserved
						// Include any other fields that need to be saved
					}))
				}
			};

			// Merge the new data with the existing data
			const updatedData = {
				...existingData,
				pages: {
					...existingData.pages,
					...pageConfig
				}
			};

			// Add the update to the batch
			batch.set(userDocRef, updatedData, { merge: true });

			// Commit the batch
			await batch.commit();

			console.log("Configuration updated for", userEmail);
		} catch (error) {
			console.error("Error saving configuration for", userEmail, ":", error);
		}
	}, 1000);

	// Function to extract UID from the URL with retry logic
	const extractUIDFromURL = async (retryCount = 0) => {
		const url = window.location.href;
		console.log(url);

		try {
			// Existing logic for 'username===' pattern
			if (url.includes("username===")) {
				const splitUrl = url.split("username===");
				console.log(splitUrl);
				return splitUrl.length === 2 ? splitUrl[1] : null;
			}
			// New logic for 'http://localhost:19006/events/G01064' pattern
			else if (url.includes("/events/G")) {
				const regex = /\/events\/(\w+)/;
				const match = regex.exec(url);
				if (match && match.length > 1) {
					const uid = match[1];
					console.log("Extracted UID:", uid);
					return uid;
				}
			}
			// New logic to handle 'username=' parameter in query string
			else if (url.includes("?username=")) {
				const params = new URLSearchParams(url.split("?")[1]);
				const uid = params.get("username");
				console.log("Extracted UID:", uid);
				return uid;
			}
			// Existing logic for other patterns
			else if (url.includes("?0")) {
				console.log("url includes /?");
				const regex = /[?&]0=%3D&1=%3D&2=%3D&3=(\w+)&4=(\d+)&5=(\d+)&6=(\d+)&7=(\d+)&8=(\d+)/;
				const match = regex.exec(url);
				console.log(match);
				if (match) {
					const uid = match.slice(1).join(""); // Concatenates the matched groups
					console.log("Extracted UID:", uid);
					return uid;
				}
			}
		} catch (error) {
			console.log("Error extracting UID:", error);
		}

		// If no UID is found and retry count is less than 1, wait and retry
		if (retryCount < 1) {
			console.log("Retrying UID extraction in 1 second...");
			await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
			return extractUIDFromURL(retryCount + 1); // Retry extraction
		}

		console.log("Failed to extract UID after retrying.");
		return null; // Return null if UID extraction fails
	};

	// Function to load the page configuration from Firestore
	const loadPageConfig = useCallback(async () => {
		// Reset all relevant state variables to avoid displaying previous user's data
		setPageOwner(null);
		setCards([]);
		setBackgroundImage(null);
		setApplyBlur(false);
		setTextColor("#000000");
		setIsPageOwnerReady(false); // Reset page owner readiness
		setShowLogoutModal(false); // Reset logout modal visibility
		setIsOwner(false); // Reset ownership state
		setHasChanges(false); // Reset unsaved changes flag
		setLoading(true); // Reset loading state
		setLoadingMessage(""); // Clear loading message
		setSuccessMessage(""); // Clear success message
		setErrorMessage(""); // Clear error message
		setAlertShown(false); // Reset alert display state
		setEditableMode(false); // Reset editable mode
		setShowBackgroundModal(false); // Reset background modal visibility
		setSelectedColor("#000000"); // Reset selected color
		setWidgets([]); // Reset widgets state
		setEventCards([]); // Reset event cards state

		try {
			const uid = await extractUIDFromURL();
			console.warn("UID:", uid);

			if (!uid) {
				console.error("No UID found in URL");
				return;
			}

			const usersRef = collection(db, "users");
			const q = query(usersRef, where("uid", "==", uid));
			const querySnapshot = await getDocs(q);

			if (querySnapshot.empty) {
				if (!alertShown) {
					alert("Sorry, This page no longer exists.");
					setAlertShown(true);
					navigation.navigate("Hub");
				}
				return;
			}

			querySnapshot.forEach((doc) => {
				const userData = doc.data();
				const pageConfig = userData?.pages?.eventsPageConfig;

				if (pageConfig) {
					console.log(userData.firstName);
					setPageOwner(userData);
					const fetchedCards = pageConfig?.cards || [];
					setCards(fetchedCards.map((card) => ({ ...card })));

					setBackgroundImage(pageConfig?.eventsCustomBackground || predefinedBackgrounds[0]);
					setApplyBlur(pageConfig?.eventsBlurEffect || false);

					if (pageConfig?.eventsTextColor) {
						setTextColor(pageConfig.textColor);
					}
				} else {
					console.log("No existing page configuration found for UID:", uid);
					setBackgroundImage(predefinedBackgrounds[0]);
					setPageOwner(userData);
					setApplyBlur(false);
					setCards([]);
				}
			});
		} catch (error) {
			console.error("Error loading page configuration:", error);
		} finally {
			setLoading(false); // Ensure loading state is reset
		}
	}, [db, predefinedBackgrounds, navigation, alertShown]);

	// Use useEffect to trigger save when cards state changes
	useEffect(() => {
		if (hasChanges) {
			debouncedSavePageConfig(cards, currentUser, db);
			setHasChanges(false); // Reset the changes flag after initiating the save
		}
	}, [cards, hasChanges]);

	// Use useFocusEffect to run loadPageConfig when the screen is focused
	useFocusEffect(
		useCallback(() => {
			loadPageConfig();
		}, [currentUserData])
	);

	const loadUserData = async () => {
		if (isPageOwnerReady) {
			return;
		}

		try {
			const uid = await extractUIDFromURL();
			console.warn(uid);

			if (!uid) {
				console.error("No UID found in URL");
				return;
			}

			setPageOwnerId(uid);

			const usersRef = collection(db, "users");
			const querySnapshot = await getDocs(query(usersRef, where("uid", "==", uid)));

			if (!querySnapshot.empty) {
				querySnapshot.forEach((doc) => {
					const userData = doc.data();
					setPageOwner(userData);
					console.log("pageOwner:", pageOwner);
					setIsPageOwnerReady(true);
				});
			} else {
				console.log("No user found with UID:", uid);
			}
		} catch (error) {
			console.error("Error fetching user data:", error);
		}
	};

	// Add a toggle edit function to enable the edit mode
	const toggleEditMode = () => {
		setEditableMode(!editableMode);
		if (editableMode) {
			// If we are in edit mode, save the configuration
			debouncedSavePageConfig(cards, currentUser, db);
		}
	};

	// Change your renderEditButton function to use isOwner instead of editableMode:
	const renderEditButton = () => {
		const headerTutorials = ['friends', 'settings'];
		const shouldHideButtons = activePopupId && headerTutorials.includes(activePopupId);
		
		if (isMobile || shouldHideButtons) {
			return null;
		}

		if (isOwner && !editableMode && !isVisitorView) {
			return (
				<View style={[styles.editButtonContainer, { zIndex: 100005 }]}>
					{/* Background Tutorial */}
					<TutorialPopup
						id="eventsBackground"
						position={{
							top: isMobile ? 120 : 0,
							right: isMobile ? 10 : isSmallDesktop ? 50 : 70
						}}
						title="Page Design"
						openDirection="bottomLeft"
						style={{ zIndex: 100010 }}
					/>


					<Pressable
						onPress={() => setShowBackgroundModal(true)}
						style={({ pressed }) => [
							styles.editButton,
							buttonHoverStyle(pressed, 'editPageSettings', hoveredButton, isMobile, isTablet, isSmallDesktop)
						]}
						onMouseEnter={() => setHoveredButton('editPageSettings')}
						onMouseLeave={() => setHoveredButton(null)}
					>
						<Text style={styles.editButtonText}>Edit Page Settings</Text>
					</Pressable>

					{/* Preview Tutorial */}
					<TutorialPopup
						id="eventsPreview"
						position={{
							top: isMobile ? 120 : 0,
							right: isMobile ? 10 : isSmallDesktop ? 335 : 430
						}}
						title="Preview Mode"
						openDirection="bottomLeft"
					/>



					<Pressable
						onPress={() => setIsVisitorView(true)}
						style={({ pressed }) => [
							styles.viewButton,
							buttonHoverStyle(pressed, 'viewAsVisitor', hoveredButton, isMobile, isTablet, isSmallDesktop)
						]}
						onMouseEnter={() => setHoveredButton('viewAsVisitor')}
						onMouseLeave={() => setHoveredButton(null)}
					>
						<Text style={styles.viewButtonText}>View as Visitor</Text>
					</Pressable>




				</View>
			);
		} else if (isOwner && editableMode && !isVisitorView) {
			return (
				<View style={[styles.editButtonContainer, { zIndex: 10 }]}>
					<Pressable style={styles.editButton} onPress={toggleEditMode}>
						<Text style={styles.editButtonText}>View Page</Text>
					</Pressable>
				</View>
			);
		}
	};

	//#region - Background Setting Logic
	// New function to render the Background Customization button
	const renderBackgroundButton = () => {
		if (isOwner && editableMode && !isVisitorView) {
			return (
				<Pressable style={styles.backgroundButton} onPress={() => setShowBackgroundModal(true)}>
					<Text style={styles.editButtonText}>Page Settings</Text>
				</Pressable>
			);
		}
	};

	const fileInputRef = useRef(null); // Add a ref for the file input

	// Function to handle background image upload
	const handleBackgroundUpload = async (event) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const storageRef = ref(getStorage(app), `eventBackgrounds/${currentUser.uid}/${file.name}`);
			uploadBytes(storageRef, file)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref).then((downloadURL) => {
						setBackgroundImage(downloadURL);
					});
				})
				.catch((error) => {
					console.error("Upload failed", error);
				});
		} else {
			console.error("No file selected");
		}
	};

	// Function to trigger the file input click
	const triggerFileInput = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	// Function to apply background and color changes
	const applyChanges = async () => {
		const userDocRef = doc(db, "users", currentUser.email.toLowerCase());

		// The document data should be an object
		const pageConfig = {
			eventsCustomBackground: backgroundImage,
			eventsTextColor: selectedColor,
			eventsBlurEffect: applyBlur,
		};

		// Use setDoc to merge the new page config into the existing 'pages' object
		try {
			await setDoc(userDocRef, { pages: { eventsPageConfig: pageConfig } }, { merge: true });
			setShowBackgroundModal(false);
		} catch (error) {
			console.error("Error applying changes:", error);
		}
	};

	// Function to toggle blur effect
	const toggleBlur = () => {
		setApplyBlur(!applyBlur);
	};

	//#endregion

	//#endregion

	// #region - Widget Functions

	//#region - RSVP Functions
	const handleRSVPChange = (option, widgetIndex) => {
		setWidgets((currentWidgets) => currentWidgets.map((widget, index) => (index === widgetIndex && widget.type === "RSVP" ? { ...widget, rsvpResponse: option } : widget)));
		setHasChanges(true); // Mark that there are unsaved changes
	};
	//#endregion

	//#region - URLPreview Functions
	// Function to handle URL change
	const handleUrlChange = async (url, index) => {
		const updatedWidgets = [...widgets];
		updatedWidgets[index].url = url;
		setWidgets(updatedWidgets);
		setHasChanges(true); // Mark that there are unsaved changes
	};

	const handleImageUpload = async (event, index) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith("image/")) {
			// Define the storage path
			const storagePath = `widgetsImages/${file.name}-${Date.now()}`;
			const storageRef = ref(getStorage(app), storagePath);

			try {
				// Upload the file to Firebase Storage
				const uploadResult = await uploadBytes(storageRef, file);
				// After upload, get the public URL
				const publicUrl = await getDownloadURL(uploadResult.ref);

				// Update the widget state with the new image URL
				setWidgets((currentWidgets) => {
					return currentWidgets.map((widget, widgetIndex) => {
						if (widgetIndex === index) {
							if (widget.type === "URLPreview" || widget.type === "PictureFrame") {
								// Update customImageUrl for URLPreview or images array for PictureFrame
								return {
									...widget,
									...(widget.type === "URLPreview" ? { customImageUrl: publicUrl } : { images: [...widget.images, publicUrl] }),
								};
							}
						}
						return widget;
					});
				});
				setHasChanges(true); // Mark that there are unsaved changes
			} catch (error) {
				console.error("Failed to upload image:", error);
			}
		}
	};

	// Function to fetch metadata
	const fetchMetadata = async (index, cards, setCards) => {
		console.log("fetchMetadata called with index:", index);
		console.log("cards:", cards);

		if (!cards || !cards[index] || !cards[index].url) {
			console.log("index:", index);
			console.error("Invalid card or URL", cards, index, cards[index], cards[index].url);
			return;
		}
		const url = cards[index].url;
		console.log("Fetching metadata for URL:", url);

		try {
			const response = await axios.post(SERVER_URL, { url }, { headers: { "Content-Type": "application/json" } });
			const metadata = response.data;

			setCards((currentCards) => {
				const updatedCards = [...currentCards];
				updatedCards[index].metadata = metadata;
				return updatedCards;
			});
			setHasChanges(true);
			console.log("metadata fetch successful", metadata);
		} catch (error) {
			console.error("Error fetching metadata:", error);
		}
	};

	const renderFetchMetadataButton = (index) => {
		return (
			<Pressable style={styles.fetchMetadataButton} onPress={() => fetchMetadata(index, cards, setCards)}>
				<Text style={styles.buttonText}>Get URL</Text>
			</Pressable>
		);
	};
	//#endregion

	//#region - PictureFrame Functions
	const updatePictureFrameImages = (newImages, widgetIndex) => {
		setWidgets((currentWidgets) => {
			const updatedWidgets = currentWidgets.map((widget, index) => {
				if (index === widgetIndex && widget.type === "PictureFrame") {
					return {
						...widget,
						images: newImages,
						// Include any additional state related to layout, such as positions or rotations
					};
				}
				return widget;
			});
			setHasChanges(true); // Mark that there are unsaved changes
			return updatedWidgets;
		});
	};

	// Function to remove an image from the PictureFrame
	const removeImageFromPictureFrame = (widgetIndex, imageUrl) => {
		setWidgets((currentWidgets) => {
			const updatedWidgets = currentWidgets.map((widget, index) => {
				if (index === widgetIndex && widget.type === "PictureFrame") {
					console.log("Current Images:", widget.images);
					console.log("Image to Remove:", imageUrl);
					const updatedImages = widget.images.filter(image => image !== imageUrl); // Remove the image
					return {
						...widget,
						images: updatedImages, // Update images array
					};
				}
				return widget;
			});
			setHasChanges(true); // Mark that there are unsaved changes
			return updatedWidgets;
		});
	};

	//#endregion

	//#region - TextBox Functions
	const handleTextChange = (newText, widgetIndex) => {
		setWidgets((currentWidgets) => {
			const updatedWidgets = [...currentWidgets];
			if (updatedWidgets[widgetIndex]?.type === "TextBox") {
				updatedWidgets[widgetIndex].text = newText;
			}
			setHasChanges(true); // Mark that there are unsaved changes
			return updatedWidgets;
		});
	};
	//#endregion

	//#endregion

	//#region - Initialization

	useEffect(() => {
		setTimeout(() => {
			loadUserData();
			loadPageConfig();
		}, 100);
	}, []);

	// Call this function within useEffect to check for ownership
	useEffect(() => {
		checkOwnership();
		// console.warn("currentUserData:", currentUserData);
	}, [currentUserData, pageOwnerId, isPageOwnerReady]);

	useEffect(() => {
		// console.log("Owner?", isOwner);	
	}, [isOwner]);

	// Inside the useEffect that checks for page ownership
	const checkOwnership = () => {
		if (!currentUserData || !pageOwnerId) {
			setIsOwner(false);
			return;
		}

		const owner = currentUserData.uid === pageOwnerId;
		setIsOwner(owner);
		console.log("Logged In User:", currentUserData, "isPageOwner:", owner);
	};

	// This code will run whenever `pageOwner` changes
	useEffect(() => {
		if (isPageOwnerReady && pageOwner) {
			console.log("PageOwner updated:", pageOwner);
		} else {
			loadUserData();
			loadPageConfig();
		}
	}, [pageOwner]);

	useEffect(() => {
		console.log("Cards Available in Hook:", cards, "Length:", cards.length);
	}, [cards]);

	const saveWidget = async (updatedCard) => {
		console.log("Attempting to save widget:", updatedCard);
		// Fetch the latest data from the database
		const userEmail = currentUser.email.toLowerCase();
		const userDocRef = doc(db, "users", userEmail);
		const userDocSnap = await getDoc(userDocRef);
		const existingData = userDocSnap.exists() ? userDocSnap.data() : {};
		const existingCards = existingData.pages?.eventsPageConfig?.cards || [];

		// Update the card in the existing cards array
		const updatedCards = existingCards.map(c => c.id === updatedCard.id ? updatedCard : c);
		console.log("Updated Cards after saving widget:", updatedCards);

		// Update the state with the updated data
		setCards(updatedCards);
		setHasChanges(true); // Mark that there are unsaved changes
	};

	useEffect(() => {
		console.log("Render Cards:", cards, "Length:", cards.length);
	}, [cards]);

	//#region - Card Background Change Handler
	const handleBackgroundChange = (newBackground) => {
		setCardBackground(newBackground);
	};
	// #endregion

	// Add UIC Check and Authorization Logic
	useEffect(() => {
		const checkAuthorization = async () => {
			setIsAuthorizationLoading(true); // Start loading
			if (!pageOwner) {
				setIsAuthorizationLoading(false);
				return;
			}

			const urlParams = new URLSearchParams(window.location.search);
			const uic = urlParams.get('uic');

			if (uic && uic === pageOwner.uic) {
				setIsAuthorized(true);
			} else if (pageOwner.uid === currentUserData?.uid) {
				setIsAuthorized(true);
			} else if (!pageOwner.isFriendsListPrivate) {
				setIsAuthorized(true);
			} else if (currentUserData) {
				if (pageOwner.friends?.followers && pageOwner.friends.followers[currentUserData.uid]) {
					setIsAuthorized(true);
				} else {
					setIsAuthorized(false);
				}
			} else {
				setIsAuthorized(false);
			}

			setIsAuthorizationLoading(false); // End loading
		};

		checkAuthorization();
	}, [pageOwner, currentUserData]);

	if (isAuthorizationLoading) {
		return <Text>Loading...</Text>; // Or any loading indicator you prefer
	}

	if (!isAuthorized && pageOwner) {
		return <PrivatePageMessage pageOwner={pageOwner} />;
	}

	// Example target date and message
	const targetDate = "2023-12-31T23:59:59"; // Replace with dynamic date if needed
	const onCompleteMessage = "The event has started!";

	return (
		<View style={styles.container}>
			{/* Display loading, success, and error messages */}
			{loadingMessage && <Text style={styles.loadingMessage}>{loadingMessage}</Text>}
			{successMessage && <Text style={styles.successMessage}>{successMessage}</Text>}
			{errorMessage && <Text style={styles.errorMessage}>{errorMessage}</Text>}

			<ImageBackground source={backgroundImage ? { uri: backgroundImage } : require("../assets/Hub Background.png")} style={styles.backgroundImage} blurRadius={applyBlur ? 10 : 0}>
				<ScrollView ref={scrollViewRef} style={styles.body}>
					{/* Apply blur effect based on state */}
					{applyBlur && <View style={styles.overlayBlur} />}
					{/* Header Bar */}
					<HeaderBar style={styles.headerBar} />

					{isOwner && showTutorial && (
						<>
							{/* Events Page Intro Tutorial */}
							<TutorialPopup
								id="eventsIntro"
								position={{
									top: isMobile ? 175 : isSmallDesktop ? 150 : 175,
									left: isMobile ? 75 : isSmallDesktop ? 50 : 75
								}}
								title="Events Page"
								openDirection="bottomRight"
							/>
						</>
					)}

					{/* Edit Button */}
					{isOwner && renderEditButton()}

					{/* Customization Button */}
					{renderBackgroundButton()}

					{/* Countdown Component */}
					{/* <Countdown targetDate={targetDate} onCompleteMessage={onCompleteMessage} /> */}

					{/* Background Customization Modal */}
					{showBackgroundModal && (
						<Modal transparent={true} animationType="slide" visible={showBackgroundModal} onRequestClose={() => setShowBackgroundModal(false)}>
							<View style={styles.modalContainer}>
								<View style={styles.modalContent}>
									<Text style={styles.modalTitle}>Customize Background and Colors</Text>

									{/* Background Selection */}
									<View style={styles.backgroundOptions}>
										<Text style={styles.modalHeader}>Change Page Background:</Text>
										<ScrollView horizontal={true}>
											{predefinedBackgrounds.map((bg, index) => (
												<Pressable key={index} onPress={() => setBackgroundImage(bg)}>
													<Image style={styles.backgroundThumbnail} source={bg} />
												</Pressable>
											))}
										</ScrollView>

										<Pressable style={{ ...styles.applyButton, marginBottom: 25 }} onPress={triggerFileInput}>
											<Text style={styles.applyButtonText}>Upload Custom Background</Text>
										</Pressable>

									</View>

									{/* Color Picker */}
									<View style={styles.colorPicker}>
										<Text style={styles.modalHeader}>Select Text Color:</Text>
										<Picker selectedValue={selectedColor} style={{ height: 50, width: 150 }} onValueChange={(itemValue, itemIndex) => setSelectedColor(itemValue)}>
											<Picker.Item label="White" value="#FFFFFF" />
											<Picker.Item label="Black" value="#000000" />
											<Picker.Item label="Red" value="#FF0000" />
											<Picker.Item label="Green" value="#00FF00" />
											<Picker.Item label="Blue" value="#0000FF" />
											<Picker.Item label="Yellow" value="#FFFF00" />
											<Picker.Item label="Cyan" value="#00FFFF" />
											<Picker.Item label="Magenta" value="#FF00FF" />
											<Picker.Item label="Gray" value="#808080" />
											<Picker.Item label="Orange" value="#FFA500" />
											<Picker.Item label="Purple" value="#800080" />
										</Picker>
									</View>

									<View style={styles.blurToggle}>
										<Text style={styles.modalHeader}>Apply Blur Effect:</Text>
										<Switch value={applyBlur} onValueChange={toggleBlur} />
									</View>

									<Pressable style={{ ...styles.applyButton, marginTop: 10 }} onPress={() => applyChanges()}>
										<Text style={styles.applyButtonText}>Apply Changes</Text>
									</Pressable>

									<Pressable style={{ ...styles.closeButton, marginTop: 0, borderRadius: 500, backgroundColor: "red" }} onPress={() => setShowBackgroundModal(false)}>
										<Text style={[styles.closeButtonText, { paddingHorizontal: 3 }]}>X</Text>
									</Pressable>

								</View>
							</View>
						</Modal>
					)}

					<View style={styles.pageHeader}>
						<View style={styles.profileContainer}>
							{pageOwner ? (

								<BackgroundProvider pageOwner={pageOwner}>
									<>
										<View style={{ ...styles.graduationCardContainer }}>
											<GraduationCard pageOwner={pageOwner} currentUserData={currentUserData} isVisitorView={isVisitorView} />
										</View>
									</>

									<CombinedCard pageOwnerId={pageOwnerId} pageOwner={pageOwner} isVisitorView={isVisitorView} />
									{/* Render Cards */}
									{loading && !cards ? <Text>Loading cards...</Text> : renderCards()}

								</BackgroundProvider>
							) : (
								<Text>Loading profile...</Text>
							)}
						</View>

					</View>


					{/* Show "Add Widgets" button when no cards are present */}
					{isOwner && cards.length === 0 && !isVisitorView && (
						<View style={styles.firstWidgetPicker}>
							{/* Widget Tutorial */}
							<TutorialPopup
								id="eventsWidget"
								position={{
									top: isMobile ? -75 : -75,
									right: isMobile ? 400 : 415,
									zIndex: 100000
								}}
								title="Add Widgets"
								openDirection="topLeft"
								style={{ zIndex: 100000 }}
							/>

							<Picker
								selectedValue={selectedWidgetType}
								onValueChange={(itemValue) => {
									setSelectedWidgetType(itemValue);
									if (itemValue) {
										addCard(itemValue);
									}
								}}
								style={styles.picker}
								itemStyle={styles.pickerItem}
							>
								<Picker.Item label="Select Widgets" value="" />
								<Picker.Item label="Countdown" value="Countdown" />
								<Picker.Item label="Text Box" value="TextBox" />
								<Picker.Item label="Slideshow" value="ImageGallery" />
								<Picker.Item label="RSVP" value="RSVP" />
								<Picker.Item label="URL Preview" value="URLPreview" />
								<Picker.Item label="Polaroids" value="PictureFrame" />
							</Picker>
						</View>
					)}
				</ScrollView>
			</ImageBackground>

			{/* Edit Mode Controls and Widget Menu */}
			{editableMode && (
				<>
					{/* Horizontally Scrollable Widget Menu */}
					<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.widgetMenuScrollView}>
						<View style={styles.widgetMenu}>
							<View style={styles.widgetSpacer}></View>
							<Pressable style={styles.widgetButton} onPress={() => selectWidget("TextBox")}>
								<Text style={styles.prevText}>Text Box</Text>
								<TextBox style={styles.previewStyle} textColor={selectedColor} />
							</Pressable>

							<Pressable style={styles.widgetButton} onPress={() => selectWidget("ImageGallery")}>
								<Text style={styles.prevText}>Image Gallery</Text>
								<ImageGallery isPreview={true} style={styles.previewStyle} />
							</Pressable>

							<Pressable style={styles.widgetButton} onPress={() => selectWidget("RSVP")}>
								<Text style={styles.prevText}>RSVP</Text>
								<RSVP style={styles.previewStyle} />

							</Pressable>

							<Pressable style={styles.widgetButton} onPress={() => selectWidget("PictureFrame")}>
								<Text style={styles.prevText}>Picture Frame</Text>
								<PictureFrame style={styles.previewStyle} />
							</Pressable>

							<Pressable style={styles.widgetButton} onPress={() => selectWidget("URLPreview")}>
								<Text style={styles.prevText}>URL Preview</Text>
								<URLPreview style={styles.previewStyle} />
							</Pressable>

							<Pressable style={styles.widgetButton} onPress={() => selectWidget("Countdown")}>
								<Text style={styles.prevText}>Countdown</Text>
								<Countdown style={styles.previewStyle} />
							</Pressable>
						</View>
					</ScrollView>

					<View style={styles.editControls}>

						{isOwner && showTutorial && (
							<TutorialPopup
								id="eventsSave"
								position={{
									bottom: isMobile ? 70 : 80,
									right: isMobile ? 10 : 20,
									zIndex: 100000
								}}
								title="Save Changes"
								openDirection="topLeft"
								style={{ zIndex: 100000 }}
							/>
						)}

						<Pressable style={styles.cancelButton} onPress={() => debouncedSavePageConfig(cards, currentUser, db)}>
							<Text style={styles.editButtonText}>Save</Text>
						</Pressable>
						<Pressable style={styles.cancelButton} onPress={() => setEditableMode(false)}>
							<Text style={styles.cancelButtonText}>Close</Text>
						</Pressable>
						{/* Add other controls as needed */}
					</View>
				</>
			)}
			{isVisitorView && (
				<View style={styles.stickyWarning}>
					<Text style={styles.warningText}>You are viewing the page as a visitor</Text>
					<Pressable style={styles.closeButton} onPress={() => setIsVisitorView(false)}>
						<Text style={styles.closeButtonText}>X</Text>
					</Pressable>
				</View>
			)}
			{/* Hidden file input */}
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleBackgroundUpload}
			/>
		</View>
	);
};

export default Events;