import React, { useState } from "react";
import { useRoute } from "@react-navigation/native";
import { View, Text, TouchableOpacity, StyleSheet, Platform, ScrollView } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	

const Checklist = () => {
	const route = useRoute();
	const { type } = route.params || {}; // No default type

	// Server URL configuration
	const SERVER_URL = process.env.NODE_ENV === "development" ? "http://localhost:3300" : REACT_APP_AWS_SERVER_URL;
console.log(SERVER_URL);
	// Checklist paths
	const checklistPaths = {
		dorm: "/pdfs/My College Dorm Checklist.pdf",
		apartment: "/pdfs/My First Apartment Checklist.pdf",
		graduation: "/pdfs/My Graduation Party Checklist.pdf",
	};

	// State to manage the active checklist
	const [activeChecklist, setActiveChecklist] = useState(type || "dorm");

	// Function to render tabs
	const renderTabs = () => (
		<View style={styles.tabsContainer}>
			{Object.keys(checklistPaths).map((checklistKey) => (
				<TouchableOpacity key={checklistKey} style={[styles.tab, activeChecklist === checklistKey && styles.activeTab]} onPress={() => setActiveChecklist(checklistKey)}>
					<Text style={styles.tabText}>{checklistKey.charAt(0).toUpperCase() + checklistKey.slice(1)}</Text>
				</TouchableOpacity>
			))}
		</View>
	);

	// Determine the PDF file URL based on the active tab
	const pdfFileUrl = `${SERVER_URL}${checklistPaths[activeChecklist]}`;

	return (
		<View style={styles.container}>
			{renderTabs()}
			<ScrollView style={{ flex: 1 }}>
				{Platform.OS === "web" ? (
					<iframe src={pdfFileUrl} style={styles.pdf} frameBorder="0">
						Your browser does not support iframes.
					</iframe>
				) : (
					<Text>PDF display is not supported on this platform.</Text>
				)}
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: "100%",
		height: "100vh",
		backgroundColor: "#4c956c",
	},
	tabsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		marginTop: 20,
	},
	tab: {
		padding: 10,
		marginHorizontal: 5,
		borderBottomWidth: 2,
		borderBottomColor: "transparent",
	},
	activeTab: {
		borderBottomColor: "#007bff",
	},
	tabText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize: 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	pdf: {
		width: "100%",
		height: "100vh",
		minHeight: 500,
	},
});

export default Checklist;
