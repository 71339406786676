import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image, TextInput, Pressable, FlatList, StyleSheet, Dimensions, ScrollView, ImageBackground } from "react-native";
import { getFirestore, doc, updateDoc, arrayUnion, setDoc, getDocs, getDoc, query, collection, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUser } from "../../Widgets/UserContext";
import exampleBackground1 from "../../assets/exBackground1.jpg";
import exampleBackground2 from "../../assets/exBackground2.jpg";
import exampleBackground3 from "../../assets/exBackground3.jpg";
import exampleBackground4 from "../../assets/exBackground4.jpg";
import exampleBackground5 from "../../assets/exBackground5.jpg";
import exampleBackground6 from "../../assets/exBackground6.jpg";
import exampleBackground7 from "../../assets/exBackground7.jpg";
import genericProfilePic from "../../assets/generic.jpg";
import logo from "../../assets/logo.png"; // Import the logo
import { REACT_APP_AWS_SERVER_URL } from '@env';	

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;


const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const defaultBackgrounds = [
  exampleBackground1,
  exampleBackground2,
  exampleBackground3,
  exampleBackground4,
  exampleBackground5,
  exampleBackground6,
  exampleBackground7,
];

const Yearbook = ({ pageOwnerId, pageOwner }) => {
	const db = getFirestore();
	const [message, setMessage] = useState("");
	const [messages, setMessages] = useState([]);
	const [signature, setSignature] = useState({ firstName: "", lastName: "" });
	const { currentUser, currentUserData } = useUser(); // Destructure to get currentUserData
	const [profilePictures, setProfilePictures] = useState({});
	const [lastThreeSenders, setLastThreeSenders] = useState([]);
	const [pageOwnerEmail, setPageOwnerEmail] = useState("");
	const flatListRef = useRef();
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [image, setImage] = useState(null); // State to store the uploaded image

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	// alert(SERVER_URL)

	useEffect(() => {
		loadMessages();
	}, [pageOwnerId]);

	useEffect(() => {
		messages.forEach((message) => {
			if (message.senderUID && !profilePictures[message.senderUID]) {
				fetchProfilePictureUrl(message.senderUID);
			}
		});
	}, [messages]);

	useEffect(() => {
		lastThreeSenders.forEach((senderUID) => {
			if (senderUID && !profilePictures[senderUID]) {
				fetchProfilePictureUrl(senderUID);
			}
		});
	}, [lastThreeSenders]);

	useEffect(() => {
    if (pageOwner) {
      if (pageOwner.gradCardBackgroundImage) {
        setBackgroundImage(pageOwner.gradCardBackgroundImage);
      } else if (pageOwner.gradCardBackgroundIndex !== undefined) {
        setBackgroundImage(defaultBackgrounds[pageOwner.gradCardBackgroundIndex]);
      } else {
        setBackgroundImage(exampleBackground2);
      }
    }
  }, [pageOwner]);

	const loadMessages = async () => {
		// Query users collection where uid field matches pageOwnerId
		const q = query(collection(db, "users"), where("uid", "==", pageOwnerId));
		const querySnapshot = await getDocs(q);

		if (!querySnapshot.empty) {
			querySnapshot.forEach((doc) => {
				const data = doc.data();

				setPageOwnerEmail(data.email || ""); // Assuming 'email' is the correct field

				if (data.messages?.yearbookMessages) {
					setMessages(data.messages.yearbookMessages.reverse());

					// Move the extraction logic here after setMessages so it can have the updated messages
					const uniqueSenders = new Set();
					const recentSenders = [];
					// Note: Make sure to use data.messages.yearbookMessages instead of messages
					for (let i = data.messages.yearbookMessages.length - 1; i >= 0; i--) {
						const senderUID = data.messages.yearbookMessages[i].senderUID;
						if (!uniqueSenders.has(senderUID)) {
							uniqueSenders.add(senderUID);
							recentSenders.push(senderUID);
							if (recentSenders.length === 3) break;
						}
					}
					setLastThreeSenders(recentSenders);
				} else {
					console.log("No yearbook messages found.");
					setMessages([]);
				}
			});
		} else {
			console.log("No user found with UID:", pageOwnerId);
		}

		// Code to extract last three unique sender UIDs
		const uniqueSenders = new Set();
		const recentSenders = [];
		for (let i = messages.length - 1; i >= 0; i--) {
			const senderUID = messages[i].senderUID;
			if (!uniqueSenders.has(senderUID)) {
				uniqueSenders.add(senderUID);
				recentSenders.push(senderUID);
				if (recentSenders.length === 3) break;
			}
		}
		setLastThreeSenders(recentSenders);
	};

	const isPageOwner = (message) => {
		return message.senderUID === pageOwnerId;
	};

	const isCurrentUser = (message) => {
		return message.senderUID === currentUserData?.uid;
	};

	const fetchProfilePictureUrl = async (uid) => {
		if (!profilePictures[uid]) {
			try {
				// Extracting the numeric part of the uid
				const numericPartOfUID = uid.substring(1);

				// Querying the Firestore collection for a document where the uid field matches the numeric part
				const usersRef = collection(db, "users");
				const q = query(usersRef, where("uid", "in", [`F${numericPartOfUID}`, `G${numericPartOfUID}`, `A${numericPartOfUID}`]));

				const querySnapshot = await getDocs(q);

				if (!querySnapshot.empty) {
					querySnapshot.forEach((docSnap) => {
						const data = docSnap.data();
						if (data.uid === `F${numericPartOfUID}` || data.uid === `G${numericPartOfUID}`) {
							const profilePicUrl = data.profilePictureURL;
							// Update state with the fetched profile picture URL
							setProfilePictures((prevPics) => ({ ...prevPics, [uid]: profilePicUrl }));
						}
					});
				} else {
					// Handle the case where no matching user is found
					console.error("No such user or no profile picture");
				}
			} catch (error) {
				console.error("Error fetching profile picture:", error);
			}
		}
	};

	const renderItem = ({ item }) => {
		const profilePicUrl = profilePictures[item.senderUID] || genericProfilePic;
		return (
			<View style={[styles.messageCard, isCurrentUser(item) ? styles.alignRight : styles.alignLeft]}>
				<Image source={{ uri: profilePicUrl }} style={styles.profileImage} />
				<View style={styles.messageContent}>
					<Text style={isCurrentUser(item) ? styles.messageSender1 : styles.messageSender2}>{`${item.senderFirstName} ${item.senderLastName}`}</Text>
					{item.image && <Image source={{ uri: item.image }} style={styles.messageImage} />}
					<Text style={isCurrentUser(item) ? styles.messageText1 : styles.messageText2}>{item.text}</Text>
					<Text style={isCurrentUser(item) ? styles.messageTimestamp1 : styles.messageTimestamp2}>{formatDate(item.timestamp)}</Text>
				</View>
			</View>
		);
	};

	const formatDate = (timestamp) => {
		// console.log("Timestamp value:", timestamp); // Log the timestamp value

		let date;
		if (timestamp instanceof Date) {
			date = timestamp;
		} else if (timestamp && typeof timestamp.toDate === 'function') {
			date = timestamp.toDate();
		} else if (typeof timestamp === 'string' || typeof timestamp === 'number') {
			date = new Date(timestamp);
		} else if (timestamp && typeof timestamp.seconds === 'number' && typeof timestamp.nanoseconds === 'number') {
			// Handle Firestore Timestamp object
			date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
		} else {
			return "Invalid date";
		}

		return new Intl.DateTimeFormat("en-US", {
			dateStyle: "medium",
			timeStyle: "short",
		}).format(date);
	};

	const handleSend = async () => {
		if (!message.trim() && !image) return;
		const newMessage = {
			text: message.trim(),
			image: image || null,
			senderFirstName: currentUserData?.firstName || signature.firstName.trim() || "Anonymous",
			senderLastName: currentUserData?.lastName || signature.lastName.trim() || "",
			senderUID: currentUserData?.uid || "anonymous",
			senderPhotoURL: currentUserData?.photoURL || "",
			timestamp: new Date(),
		};
		const q = query(collection(db, "users"), where("uid", "==", pageOwnerId));
		const querySnapshot = await getDocs(q);
		if (!querySnapshot.empty) {
			querySnapshot.forEach(async (docSnapshot) => {
				const userDocRef = doc(db, "users", docSnapshot.id);
				const docData = await getDoc(userDocRef);
				if (docData.exists() && docData.data().messages && docData.data().messages.yearbookMessages) {
					await updateDoc(userDocRef, {
						"messages.yearbookMessages": arrayUnion(newMessage),
					});
				} else {
					const messagesObj = { yearbookMessages: [newMessage] };
					await setDoc(userDocRef, { messages: messagesObj }, { merge: true });
				}
				setMessage("");
				setImage(null); // Clear the image after sending
				setSignature({ firstName: "", lastName: "" });
				loadMessages();
			});
		}
		if (newMessage.senderUID !== pageOwnerId) {
			if (pageOwnerEmail) {
				sendEmailNotification(pageOwnerId, newMessage.text, pageOwnerEmail);
			} else {
				console.error("Page owner email is not available.");
			}
		}
	};

	const sendEmailNotification = async (pageOwner, messageText, pageOwnerEmail) => {
		try {
			const response = await fetch(`${SERVER_URL}/send-yearbook-message-notification`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					pageOwner,
					message: messageText,
					pageOwnerEmail,
				}),
			});

			if (!response.ok) {
				// Attempt to parse the response body for more detailed error info
				const errorBody = await response.json();
				throw new Error(`Failed to send email notification: ${errorBody.message || response.statusText}`);
			}

			console.log("Email notification sent successfully");
		} catch (error) {
			console.error("Error sending email notification:", error);
		}
	};

	const handleImageUpload = async (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith("image/")) {
			const storageRef = ref(getStorage(), `yearbookImages/${file.name}-${Date.now()}`);
			const uploadResult = await uploadBytes(storageRef, file);
			const publicUrl = await getDownloadURL(uploadResult.ref);
			setImage(publicUrl);
		}
	};

	return (

		<View style={styles.scrollView}>
			<View style={styles.container}>
				<View style={styles.profilePicturesContainer}>
					{lastThreeSenders.map((senderUID) => (
						<Image key={senderUID} source={{ uri: profilePictures[senderUID] || "default-profile-image-url" }} style={styles.smallProfileImage} />
					))}
				</View>

				<Text style={styles.header}>Yearbook</Text>

				<ImageBackground
					source={{ uri: backgroundImage }}
					imageStyle={styles.yearbookImage}
					style={styles.yearbookContainer}
				>
					<FlatList
						// data={pageOwnerId === currentUserData?.uid ? [{ isCongratulatory: true }, ...messages].reverse() : messages.reverse()} // Reverse the messages array
						data={pageOwnerId ? [{ isCongratulatory: true }, ...messages].reverse() : messages.reverse()} // Reverse the messages array
						keyExtractor={(item, index) => "message-" + index}
						renderItem={({ item }) => {
							if (item.isCongratulatory) {
								return (
									<View style={[styles.messageCard, styles.alignLeft]}>
										<Image source={logo} style={styles.profileImage} />
										<View style={styles.messageContent}>
											<Text style={styles.messageSender2}>The MyTassel Team</Text>
											<Text style={styles.messageText2}>
											Congratulations on your accomplishment! Your hard work and perseverance paid off! As you share your page, Friends and Family can use this space to share messages, memories, and upload their favorite photos to celebrate YOU!
											</Text>
										</View>
									</View>
								);
							}
							return renderItem({ item });
						}}
						contentContainerStyle={{ paddingBottom: 20, paddingHorizontal: 20, backgroundColor: "rgba(255, 255, 255, 0.8)", borderRadius: 10 }} // Add padding at the bottom
					// You can also use ListFooterComponent={<View style={{ height: 20 }} />} for padding
					/>
					{/* UI Components */}
					<View style={styles.yearbookInputContainer}>
						<TextInput
							style={[styles.yearbookInput, { marginTop: 10 }]} // Add top margin
							placeholder="Write a message..."
							value={message}
							onChangeText={setMessage}
						/>
						{!currentUserData?.uid && (
							<>
								<TextInput style={styles.input} placeholder="First Name" value={signature.firstName} onChangeText={(text) => setSignature({ ...signature, firstName: text })} />
								<TextInput style={styles.input} placeholder="Last Name" value={signature.lastName} onChangeText={(text) => setSignature({ ...signature, lastName: text })} />
							</>
						)}
					</View>
					<View style={styles.buttonContainer}>
						<Pressable style={styles.uploadButton} onPress={() => document.getElementById('fileInput').click()}>
							<Text style={styles.uploadButtonText}>Upload Photo</Text>
						</Pressable>
						<input
							id="fileInput"
							type="file"
							onChange={handleImageUpload}
							accept="image/*"
							style={{ display: 'none' }}
						/>
						<Pressable style={styles.sendButton} onPress={handleSend}>
							<Text style={styles.sendButtonText}>Send</Text>
						</Pressable>
					</View>
					{image && (
						<View style={styles.previewContainer}>
							<Image source={{ uri: image }} style={styles.previewImage} />
						</View>
					)}
				</ImageBackground>
			</View>
		</View>

	);
};

// Styles
const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%",
		minHeight: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 690 : 650,
		minWidth: 450,
		maxWidth: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 400 : 400,
		maxHeight: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 690 : 690,
	},
	yearbookContainer: {
		flex: 1,
		backgroundColor: "#fefde2",
		padding: 20,
		borderRadius: 25,
		width: "100%",
		height: "100%",
		maxWidth: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 400 : 400,
		maxHeight: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 600 : 1500,
		minHeight: "100%",
		overflow: "hidden",
	},
	innerContainer: {
		flex: 1,
		backgroundColor: "lightgrey",
		padding: 20,
		borderRadius: 25,
		width: "90%",
		height: "100%",
		maxWidth: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 400 : 400,
		maxHeight: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 600 : 600,
		minHeight: "100%",
	},
	header: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 50,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		paddingBottom: 25,
	},
	messageCard: {
		padding: 10,
		borderRadius: 10,
		marginVertical: 5,
		flexDirection: "row",
		alignItems: "center",
		shadowColor: "rgba(0, 0, 0, 0.5)",
		shadowOffset: { width: 1, height: 1 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	profileImage: {
		width: 25,
		height: 25,
		borderRadius: 25,
		marginRight: 10,
	},
	profilePicturesContainer: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 15, // Adjust spacing between this container and the Yearbook title
	},
	smallProfileImage: {
		width: 50, // Adjust size as needed
		height: 50, // Adjust size as needed
		borderRadius: 25, // Make it round
		marginRight: 5, // Space between images
	},
	messageContent: {
		flex: 1,
	},
	messageSender1: {
		fontWeight: "bold",
		marginBottom: 5,
		color: "#fff",
		textShadowColor: "rgba(0, 0, 0, 0.5)",
		textShadowRadius: 3.84,
	},
	messageSender2: {
		fontWeight: "bold",
		marginBottom: 5,
		color: "#000",
	},
	messageText1: {
		fontSize: 16,
		color: "#fff",
	},
	messageText2: {
		fontSize: 16,
		color: "#000",
	},
	messageTimestamp1: {
		fontSize: 8,
		color: "#ddd",
		marginTop: 2,
	},
	messageTimestamp2: {
		fontSize: 8,
		color: "#000",
		marginTop: 2,
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		padding: 10,
		fontSize: 18,
		borderRadius: 10,
		marginVertical: 10,
		backgroundColor: "#fff",
	},
	button: {
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		paddingVertical: 10,
		paddingHorizontal: 20,
		marginVertical: 10,
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 18,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
	signatureContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	signatureInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: "#ddd",
		padding: 10,
		fontSize: 16,
		borderRadius: 10,
		marginHorizontal: 5,
		backgroundColor: "#fff",
	},
	alignRight: {
		alignSelf: "flex-end",
		backgroundColor: "#4c956c",
	},
	alignLeft: {
		alignSelf: "flex-start",
		backgroundColor: "#fefde2"
	},
	scrollView: {
		flex: 1,
	},
	yearbookInputContainer: {
		flexDirection: 'column', // Change to column
		alignItems: 'center',
		marginBottom: 10,
	},
	yearbookInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: '#ddd',
		padding: 10,
		fontSize: 18,
		borderRadius: 10,
		backgroundColor: '#fff',
		width: '100%',
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 10, // Add top margin
	},
	uploadButton: {
		backgroundColor: '#4c956c',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 40,
		paddingVertical: 10,
		paddingHorizontal: 20,
		marginRight: 10,
	},
	uploadButtonText: {
		fontFamily: 'Orpheus Pro',
		fontSize: 18,
		textAlign: 'center',
		color: '#e4e4e4',
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: 'rgba(0, 0, 0, 0.09)',
		marginHorizontal: 25,
	},
	sendButton: {
		backgroundColor: '#4c956c',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 40,
		paddingVertical: 10,
		paddingHorizontal: 20,
	},
	sendButtonText: {
		fontFamily: 'Orpheus Pro',
		fontSize: 18,
		textAlign: 'center',
		color: '#e4e4e4',
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: 'rgba(0, 0, 0, 0.09)',
		marginHorizontal: 25,
	},
	previewContainer: {
		marginTop: 10,
		alignItems: 'center',
	},
	previewImage: {
		width: 100,
		height: 100,
		borderRadius: 10,
	},
	messageImage: {
		width: 100,
		height: 100,
		borderRadius: 10,
		marginBottom: 5,
	},
});

export default Yearbook;
