import React, { createContext, useState, useEffect, useContext } from "react";
import { View, Text, TextInput, ScrollView, ImageBackground, Animated, Alert, Modal, Button, Pressable, Image, TouchableOpacity, Dimensions } from "react-native";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, collection, getDocs, query, where } from "firebase/firestore";
import { app } from "../firebase";
import { useUser } from "../Widgets/UserContext";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/HubStyles";
import Friends from "../Widgets/Friends";
import friendsIcon from "../assets/friends.png"; // Added import for friends icon
import friendsNotificationIcon from "../assets/friends-notification.png"; // Added import for notification icon
import PromptSignUp from "../Widgets/PromptSignUp";
import HeaderBar from "../Widgets/HeaderBar";
import AsyncStorage from '@react-native-async-storage/async-storage'; // Import AsyncStorage
import { TutorialPopup } from '../Screens/Helpers/Tutorials';
import { useTutorial } from '../Screens/Helpers/Tutorials'; // Ensure this import is present
import { REACT_APP_AWS_SERVER_URL } from '@env';	

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

const isDevelopment = process.env.NODE_ENV === "development";
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Hub = ({ route }) => {
	const { showRegistryModal } = route.params || {};
	const navigation = useNavigation();
	const { 
		setShowTutorial, 
		setActivePopupId, 
		showTutorial, 
		isLoading,
		tutorialsToShow,
		setClosedTutorials,
		setTutorialsToShow,
		tutorialSteps,
	} = useTutorial();
	// Initialize Firestore
	const db = getFirestore(app);
	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();
	const [showFriends, setShowFriends] = useState(false);
	const auth = getAuth(app);

	let username;

	if (currentUserData) {
		console.warn("currentUserData:", currentUserData);
		username = currentUserData.uid;
	}

	const [showLogoutModal, setShowLogoutModal] = React.useState(false);
	const [showEventPagePrompt, setShowEventPagePrompt] = useState(false);
	const [showRegistryPagePrompt, setShowRegistryPagePrompt] = useState(false);
	const [isDaytime, setIsDaytime] = useState(true);
	const [showNewAccountPopup, setShowNewAccountPopup] = useState(false);
	const [pageUpdated, setPageUpdated] = useState(false);
	const [friendRequests, setFriendRequests] = useState([])
	const [showPromptSignUp, setShowPromptSignUp] = useState(false);
	const [hoveredButton, setHoveredButton] = useState(null);

	const buttonHoverStyle = (isHovered, buttonId) => ({
		backgroundColor: isHovered || hoveredButton === buttonId ? "#5fba86" : "#4c956c", // Darker green on hover
		borderRadius: 40,
		alignItems: "center",
		justifyContent: "center",
		width: isMobile ? 284 : isTablet ? 284 : isSmallDesktop ? 300 : 400,
		height: isMobile ? 55 : isTablet ? 55 : isSmallDesktop ? 60 : 75,
		marginBottom: isMobile ? 20 : 0,
		transform: [{ scale: isHovered || hoveredButton === buttonId ? 1.025 : 1 }], // Slight scale on hover
	});

	// #region - Testing Login
	useEffect(() => {
		if (isDevelopment) {
			const testLogin = async () => {
				try {
					const testEmails = [
						"Tjhawk27@gmail.com", // Trevor
						"trevorhawkinsofficial@gmail.com", // Olivia Nichols
						"trevorhawkins@makenoisemedia.io", // Sam Minix
						"info@dsignable.com" // Tristan
					];
					const password = "Newtjhawk27";
					const lowerCaseEmail = testEmails[3].toLowerCase(); // Default to Jennifer

					const userCredential = await signInWithEmailAndPassword(auth, lowerCaseEmail, password);
					const user = userCredential.user;

					const userDocRef = doc(db, "users", lowerCaseEmail);
					const userDocSnap = await getDoc(userDocRef);

					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						setCurrentUser(user); // Set the auth user
						setCurrentUserData(userData); // Set the Firestore user data
					} else {
						console.error("No such user data!");
					}
				} catch (error) {
					console.error("Error logging in with test account", error);
				}
			};
			// testLogin();
		}
	}, []);
	// #endregion

	const navigateToChecklist = (type) => {
		navigation.navigate("Checklist", { type });
	};

	//#region - General Functions
	const handleLinkPress = (pageName, params = {}) => {
		console.log(`Navigating to ${pageName}`);
		if (pageName === "Registry") {
			// Updated to navigate without query parameter
			navigation.navigate("Registry", { username: params.username ? params.username : currentUserData.uid });
		} else {
			navigation.navigate(pageName, params);
		}
	};

	// Get Current Time
	useEffect(() => {
		const currentHour = new Date().getHours();
		setIsDaytime(currentHour >= 5 && currentHour < 17);
	}, []);

	// Show new account popup
	useEffect(() => {
		const checkNewAccountPopup = async () => {
			try {
				console.log("currentUser:", currentUser);

				if (currentUser && !currentUser.isAnonymous) {
					const userDocRef = doc(db, "users", currentUser.email);
					const userDocSnap = await getDoc(userDocRef);

					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						console.log("Fetched userData:", userData);

						if (userData.newAccount) {
							if (userData.pages?.events || userData.pages?.registry) {
								// Update newAccount to false since they already have pages
								await setDoc(userDocRef, { newAccount: false }, { merge: true });
								setCurrentUserData({ ...userData, newAccount: false });
								return;
							}
							setTimeout(() => {
								setShowNewAccountPopup(true);
							}, 1000); // Show the popup after 1 second
						} else {
							console.log("Not showing new account popup");
							setShowNewAccountPopup(false); // Ensure popup is not shown otherwise
						}
					} else {
						console.error("No such user data!");
					}
				}
			} catch (error) {
				console.error("Error checking new account popup:", error);
			}
		};
		checkNewAccountPopup();
	}, [currentUser]);

	// Inside your component or function where you call generateUniqueUid
	useEffect(() => {
		if (currentUserData?.newAccount) {
			if (currentUserData.pages?.events || currentUserData.pages?.registry) {
				console.log("Pages already exist, skipping uid generation");
				return;
			}
			generateUniqueUid(db)
				.then((uid) => {
					console.log("Unique UID generated:", uid);
					// Additional logic to handle the newly generated UID
				})
				.catch((error) => {
					console.error("Error generating unique UID:", error);
				});
		} else if (currentUserData) {
			// sendGenericWelcomeEmail(currentUserData);
			// sendGraduateWelcomeEmail(currentUserData);
		}
	}, [currentUserData, db, pageUpdated]); // Ensure 'db' is included if it's a dependency

	// Then, when calling generateUniqueUid, pass db explicitly
	const handleUserResponseYes = async () => {
		try {
			if (currentUser) {
				console.log("User responded YES:", currentUser.email);
				const uniqueUid = await generateUniqueUid(db);
				const userDocRef = doc(db, "users", currentUser.email);

				// Log the data being set
				console.log("Setting Firestore document with data:", {
					accountType: "Graduate",
					uid: uniqueUid,
					newAccount: false,
					isFriendsListPrivate: true
				});

				await setDoc(userDocRef, {
					accountType: "Graduate",
					uid: uniqueUid,
					newAccount: false,
					isFriendsListPrivate: true // Ensure this is set to true
				}, { merge: true });

				// Fetch the updated user data
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const updatedData = userDocSnap.data();
					console.log("Updated Firestore document data:", updatedData);
					setCurrentUserData(updatedData);
				} else {
					console.error("Document does not exist after update.");
				}

				alert("Your account has been updated. You can change this at any time in the profile menu.");
			}

			setShowNewAccountPopup(false);
			setPageUpdated(true);
			sendGraduateWelcomeEmail(currentUserData);
		} catch (error) {
			console.error("Error handling user response YES:", error);
		}
	};

	const sendGraduateWelcomeEmail = async (currentUserData) => {
		let userEmail = currentUserData.email;
		let firstName = currentUserData.firstName;
		try {
			const response = await fetch(`${SERVER_URL}/send-graduate-welcome-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userEmail, firstName }),
			});

			const responseData = await response.text(); // or .json() if your server responds with JSON
			console.log("Welcome email response:", responseData);
		} catch (error) {
			console.error("Error sending welcome email:", error);
		}
	};


	const sendGenericWelcomeEmail = async (currentUserData) => {
		let userEmail = currentUserData.email;
		let firstName = currentUserData.firstName;
		try {
			const response = await fetch(`${SERVER_URL}/send-generic-welcome-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userEmail, firstName }),
			});

			const responseData = await response.text(); // or .json() if your server responds with JSON
			console.log("Welcome email response:", responseData);
		} catch (error) {
			console.error("Error sending welcome email:", error);
		}
	};

	const handleUserResponseNo = async () => {
		try {
			if (currentUser) {
				console.log("User responded NO:", currentUser.email);
				const userDocRef = doc(db, "users", currentUser.email);
				await setDoc(userDocRef, { newAccount: false }, { merge: true });

				// Fetch the updated user data
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const updatedData = userDocSnap.data();
					setCurrentUserData(updatedData);
				}

				alert("You can change your account details at any time in the profile menu.");
			}

			setShowNewAccountPopup(false);
			sendGenericWelcomeEmail(currentUserData);
		} catch (error) {
			console.error("Error handling user response NO:", error);
		}
	};



	// Your generateUniqueUid function remains the same, just ensure db is passed correctly
	const generateUniqueUid = async (db) => {
		let isUnique = false;
		let uid;

		while (!isUnique) {
			// Generate a random 5-digit number and prepend 'G'
			uid =
				"G" +
				Math.floor(Math.random() * 100000)
					.toString()
					.padStart(5, "0");

			// Attempt to fetch documents from the 'users' collection where 'uid' matches the generated uid
			const q = query(collection(db, "users"), where("uid", "==", uid));
			const querySnapshot = await getDocs(q);

			// Check if the generated uid is unique
			isUnique = querySnapshot.empty;
		}

		console.log("Generated unique UID:", uid);
		return uid;
	};

	// Determine greeting based on current time
	const getGreeting = () => {
		const currentHour = new Date().getHours();
		if (currentHour < 12) {
			return "Good Morning";
		} else if (currentHour < 18) {
			return "Good Afternoon";
		} else {
			return "Good Evening";
		}
	};

	const handleLogout = async () => {
		try {
			await signOut(auth);
			setCurrentUser(null);
			setCurrentUserData(null);
			navigation.navigate("My Tassel - Welcome");
		} catch (error) {
			console.error("Error during logout:", error);
		}
	};

	// Function to toggle between Login and Logout button based on currentUserData state
	const renderLoginOrLogoutButton = () => {
		if (currentUserData) {
			return (
				<Pressable style={styles.logoutButton} onPress={() => setShowLogoutModal(true)}>
					<Text style={styles.loginButtonText}>Logout</Text>
				</Pressable>
			);
		} else {
			return (
				<Pressable style={styles.loginButton} onPress={() => navigation.navigate("My Tassel - Welcome", { popupType: "Login" })}>
					<Text style={styles.loginButtonText}>Login</Text>
				</Pressable>
			);
		}
	};
	//#endregion

	//#region - Event Page Creation
	const checkAndPromptEventPageCreation = async () => {
		if (currentUser && currentUserData && currentUserData.uid.startsWith("G")) {
			const userDocRef = doc(db, "users", currentUserData.email);
			const docSnap = await getDoc(userDocRef);

			if (docSnap.exists()) {
				const userData = docSnap.data();
				const eventsPageUrl = userData.pages?.events;

				if (eventsPageUrl) {
					// If an events page URL exists, navigate to it
					navigation.navigate("Announcements", { username: currentUserData.uid });
				} else {
					promptCreateEventPage();
				}
			} else {
				alert("User data not found.");
			}
		} else {
			alert("Please set your account type to 'Graduate' in the Profile menu before attempting to create an Announcements page");
		}
	};

	const promptCreateEventPage = () => {
		setShowEventPagePrompt(true);
	};

	const createEventPage = async () => {
		const eventsPageUrl = `https://mytassel.com/events/${currentUserData.uid}`;
		const userDocRef = doc(db, "users", currentUserData.email);

		await setDoc(userDocRef, { pages: { events: eventsPageUrl } }, { merge: true });
		setShowEventPagePrompt(false);
		// Navigate to the new event page
		navigation.navigate("Announcements", { username: currentUserData.uid });
	};

	//#endregion

	//#region - Registry Page Creation
	const checkAndPromptRegistryPageCreation = async () => {
		if (currentUser && currentUserData && currentUserData.uid.startsWith("G")) {
			const userDocRef = doc(db, "users", currentUserData.email);
			const docSnap = await getDoc(userDocRef);

			if (docSnap.exists()) {
				const userData = docSnap.data();
				const registryPageUrl = userData.pages?.registry;

				if (registryPageUrl) {
					// If a registry page URL exists, navigate to it
					handleLinkPress("Registry", { username: currentUserData.uid });
				} else {
					promptCreateRegistryPage();
				}
			} else {
				alert("User data not found.");
			}
		} else {
			alert("Please set your account type to 'Graduate' in the Profile menu before attempting to create a Registry page");
		}
	};

	const promptCreateRegistryPage = () => {
		setShowRegistryPagePrompt(true);
	};

	// Function to actually create the registry page
	const createRegistryPage = async () => {
		if (!currentUserData) return; // Ensure there's current currentUserData data

		const registryPageUrl = `https://mytassel.com/registry/${currentUserData.uid}`;
		const userDocRef = doc(db, "users", currentUserData.email);

		try {
			// Update Firestore with the new registry page URL
			await setDoc(userDocRef, { pages: { registry: registryPageUrl } }, { merge: true });

			// Close the prompt
			setShowRegistryPagePrompt(false);

			// Navigate to the newly created registry page
			handleLinkPress("Registry", { username: currentUserData.uid });
		} catch (error) {
			console.error("Failed to create registry page:", error);
			alert("An error occurred while creating the registry page. Please try again.");
		}
	};
	//#endregion

	// #region - Resources
	const slideAnimDorm = React.useRef(new Animated.Value(-100)).current;
	const slideAnimApartment = React.useRef(new Animated.Value(-200)).current;
	const slideAnimGraduation = React.useRef(new Animated.Value(-300)).current;
	const slideAnimCatalogue = React.useRef(new Animated.Value(-400)).current;
	const slideAnimPrivacy = React.useRef(new Animated.Value(-400)).current;
	const slideAnimTerms = React.useRef(new Animated.Value(-400)).current;

	const slideIn = () => {
		Animated.timing(slideAnimDorm, {
			toValue: 250,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimApartment, {
			toValue: 200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimGraduation, {
			toValue: 150,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: 100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: 50,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: 0,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const slideOut = () => {
		Animated.timing(slideAnimDorm, {
			toValue: -100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimApartment, {
			toValue: -200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimGraduation, {
			toValue: -300,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};
	// #endregion

	const navigateToCatalogue = () => {
		navigation.navigate("Catalogue", { isFromHub: true });
	};

	useEffect(() => {
		if (showRegistryModal) {
			setShowRegistryPagePrompt(true);
		}
	}, [showRegistryModal]);

	// Define the total number of tutorials on this page
	const totalTutorials = Object.keys(tutorialSteps).length;

	useEffect(() => {
		if (!isLoading && !showNewAccountPopup) {
			console.log("Setting up tutorials in Hub");
			setShowTutorial(true);
			if (tutorialsToShow.includes('welcome')) {
				setActivePopupId("welcome");
			}
		}
	}, [isLoading, showNewAccountPopup, tutorialsToShow]);

	useEffect(() => {
		const fetchCompletedTutorials = async () => {
			if (!currentUserData?.email) return;

			try {
				const userDoc = doc(db, "users", currentUserData.email);
				const userDocSnap = await getDoc(userDoc);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setClosedTutorials(userData.completedTutorials || {});
				}
			} catch (error) {
				console.error("Error fetching completed tutorials:", error);
			}
		};

		fetchCompletedTutorials();
	}, [currentUserData]);

	// Handle initial tutorial setup after loading
	useEffect(() => {
		if (!isLoading && !showNewAccountPopup) {
			setShowTutorial(true);
			setActivePopupId("welcome");
		}
	}, [isLoading, showNewAccountPopup]);

	// Separate loading state from render logic
	if (isLoading) {
		return (
			<View style={styles.body}>
				<HeaderBar />
				{/* You might want to add a loading indicator here */}
			</View>
		);
	}

	return (
		<View style={styles.body}>
			<ImageBackground source={isDaytime ? require("../assets/Popup Background.png") : require("../assets/Hub Background.png")} style={styles.backgroundImage}>

				<PromptSignUp isVisible={showPromptSignUp} onClose={() => setShowPromptSignUp(false)} redirectPage="Profile" />

				<div style={{ ...styles.overlayBlur }} />
				{/* Header Bar */}
				<HeaderBar />


				<View style={styles.section}>
					{/* Welcome Text */}
					<TutorialPopup
							id="welcome"
							steps={tutorialSteps.welcome}
							position={{ top: isSmallDesktop ? 75 : 90, left: isSmallDesktop ? 200 : 250 }}
							title="Welcome"
							openDirection="bottomRight"
							totalTutorials={totalTutorials} // Pass the total number of tutorials
						/>
					<View style={styles.welcomeArea}>
				
						<Text style={styles.welcomeText}>
							{getGreeting()}
							{currentUserData ? ", " + currentUserData.firstName + "!" : "!"}
						</Text>
					</View>

					<View style={styles.descriptionArea}>
						<Text style={styles.descriptionText}>
							{currentUserData && currentUserData.accountType === "Graduate" ?
								`Welcome Graduate! \nGet started creating your personal registry or announcement page for friends and family to visit` :
								currentUserData && currentUserData.accountType === "Friends & Family" ?
									`Welcome to MyTassel! \nYou can visit your graduate's registry and announcement page and get updates about their events` :
									currentUserData && currentUserData.accountType === "admin" ?
										`Welcome Admin! \nManage users and oversee the platform.` :
										`Welcome to MyTassel! \nPlease sign up or log in to access all features.`}
						</Text>
					</View>


					<View style={styles.buttonsContainer}>
						<View style={styles.buttonArea}>
							<TutorialPopup
								id="profile"
								steps={tutorialSteps.profile}
								position={{ display: isMobile ? 'none' : 'block', top: isSmallDesktop ? -15 : -15, right: isSmallDesktop ? -15 : -15 }}
								title="Profile"
								openDirection="topLeft"
								totalTutorials={totalTutorials} // Pass the total number of tutorials
							/>
							<Pressable
								style={({ pressed }) => buttonHoverStyle(pressed, 'profile')}
								onPress={() => {
									if (currentUserData) {
										handleLinkPress("Profile");
									} else {
										setShowPromptSignUp(true);
									}
								}}
								onMouseEnter={() => setHoveredButton('profile')}
								onMouseLeave={() => setHoveredButton(null)}
							>
								<Text style={styles.buttonText}>My Profile</Text>
							</Pressable>
							{!isMobile && (
								<Text style={styles.buttonDescription}>Create or edit your profile & preferences</Text>
							)}
						</View>

						{currentUserData?.accountType === "admin" ?
							<View style={styles.buttonArea}>
								<Pressable
									style={({ pressed }) => buttonHoverStyle(pressed, 'admin')}
									onPress={() => navigation.navigate("Admin")}
									onMouseEnter={() => setHoveredButton('admin')}
									onMouseLeave={() => setHoveredButton(null)}
								>
									<Text style={styles.buttonText}>Admin Page</Text>
								</Pressable>
								{!isMobile && (
									<Text style={styles.buttonDescription}>Manage users and oversee the platform</Text>
								)}
							</View>
							:
							<>
								<View style={styles.buttonArea}>
									<TutorialPopup
										id="announcement"
										steps={tutorialSteps.announcement}
										position={{ display: isMobile ? 'none' : 'block', top: isSmallDesktop ? -15 : -15, right: isSmallDesktop ? -15 : -15 }}
										title="Announcement"
										openDirection="topLeft"
										totalTutorials={totalTutorials} // Pass the total number of tutorials
									/>
									<Pressable
										style={({ pressed }) => buttonHoverStyle(pressed, 'announcement')}
										onPress={checkAndPromptEventPageCreation}
										onMouseEnter={() => setHoveredButton('announcement')}
										onMouseLeave={() => setHoveredButton(null)}
									>
										<Text style={styles.buttonText}>My Announcement</Text>
									</Pressable>
									{!isMobile && (
										<Text style={styles.buttonDescription}>Create or edit your announcement page, add events, & invite friends & family</Text>
									)}
								</View>

								<View style={styles.buttonArea}>
									<TutorialPopup
										id="registry"
										steps={tutorialSteps.registry}
										position={{ display: isMobile ? 'none' : 'block', top: isSmallDesktop ? -15 : -15, right: isSmallDesktop ? -15 : -15 }}
										title="Registry"
										openDirection="topLeft"
										totalTutorials={totalTutorials} // Pass the total number of tutorials
									/>
									<Pressable
										style={({ pressed }) => buttonHoverStyle(pressed, 'registry')}
										onPress={checkAndPromptRegistryPageCreation}
										onMouseEnter={() => setHoveredButton('registry')}
										onMouseLeave={() => setHoveredButton(null)}
									>
										<Text style={styles.buttonText}>My Registry</Text>
									</Pressable>
									{!isMobile && (
										<Text style={styles.buttonDescription}>Create or view your graduation registry</Text>
									)}
								</View>
							</>
						}
					</View>
				</View>

				{/* Custom Modal for Event Page Creation */}
				<Modal animationType="slide" transparent={true} visible={showEventPagePrompt} onRequestClose={() => setShowEventPagePrompt(false)}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Create Announcement Page</Text>
							<Text>Would you like to create your announcement page?</Text>

							<Pressable style={[styles.button, styles.buttonOpen]} onPress={createEventPage}>
								<Text style={styles.textStyle}>Yes</Text>
							</Pressable>

							<Pressable style={[styles.button, styles.buttonClose]} onPress={() => setShowEventPagePrompt(false)}>
								<Text style={styles.textStyle}>No</Text>
							</Pressable>
						</View>
					</View>
				</Modal>

				{/* Custom Modal for Registry Page Creation */}
				<Modal animationType="slide" transparent={true} visible={showRegistryPagePrompt} onRequestClose={() => setShowRegistryPagePrompt(false)}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Create Registry</Text>
							<Text>Would you like to create your registry?</Text>

							<Pressable style={[styles.button, styles.buttonOpen]} onPress={createRegistryPage}>
								<Text style={styles.textStyle}>Yes</Text>
							</Pressable>

							<Pressable style={[styles.button, styles.buttonClose]} onPress={() => setShowRegistryPagePrompt(false)}>
								<Text style={styles.textStyle}>No</Text>
							</Pressable>
						</View>
					</View>
				</Modal>
				{/* New Account Modal */}
				<Modal style={styles.blurredContainer} animationType="slide" transparent={true} visible={showNewAccountPopup} onRequestClose={() => setShowNewAccountPopup(false)}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Welcome to My Tassel!</Text>
							<Text style={styles.modalText}>Do you need to create an announcement, event, and/or registry?</Text>
							<Pressable style={[styles.button, styles.buttonGreen]} onPress={handleUserResponseYes}>
								<Text style={styles.textStyle}>Yes</Text>
							</Pressable>
							<Pressable style={[styles.button, styles.buttonRed]} onPress={handleUserResponseNo}>
								<Text style={styles.textStyle}>No</Text>
							</Pressable>
						</View>
					</View>
				</Modal>

				<View
					style={styles.ribbonContainer}
					onMouseEnter={slideIn}
					onMouseLeave={slideOut}
				>
					<TutorialPopup
						id="resources"
						steps={tutorialSteps.resources}
						position={{
							top: isSmallDesktop ? -15 : -15,
							right: isSmallDesktop ? -25 : -20
						}}
						title="Resources"
						openDirection="topLeft"
					/>
					<Text style={{ ...styles.ribbonText, width: 100, textAlign: 'center', zIndex: 1001 }}>Resources</Text>
					<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimDorm, zIndex: 9999 }}>
						<Pressable
							onPress={() => navigateToChecklist("dorm")}
						>
							<Text style={styles.ribbonText}>Dorm Checklist</Text>
						</Pressable>
					</Animated.View>
					<Animated.View style={{ ...styles.ribbon2, bottom: slideAnimApartment, zIndex: 9999 }}>
						<Pressable
							onPress={() => navigateToChecklist("apartment")}
						>
							<Text style={styles.ribbonText}>Apartment Checklist</Text>
						</Pressable>
					</Animated.View>

					<Animated.View style={{ ...styles.ribbon3, bottom: slideAnimGraduation, zIndex: 9999 }}>
						<Pressable
							onPress={() => navigateToChecklist("graduation")}
						>
							<Text style={styles.ribbonText}>Graduation Checklist</Text>
						</Pressable>
					</Animated.View>

					<Animated.View style={{ ...styles.ribbon4, bottom: slideAnimCatalogue }}>
						<Pressable
							onPress={navigateToCatalogue}
						>
							<Text style={styles.ribbonText}>Catalogue</Text>
						</Pressable>
					</Animated.View>

					<Animated.View style={{ ...styles.ribbon5, bottom: slideAnimPrivacy }}>
						<Pressable
							onPress={() => navigation.navigate("Privacy")}
						>
							<Text style={styles.ribbonText}>Privacy Policy</Text>
						</Pressable>
					</Animated.View>

					<Animated.View style={{ ...styles.ribbon6, zIndex: 1002, bottom: slideAnimTerms }}>
						<Pressable
							onPress={() => navigation.navigate("Terms")}
						>
							<Text style={{ ...styles.ribbonText, zIndex: 1002 }}>Terms & Conditions</Text>
						</Pressable>
					</Animated.View>
				</View>
			</ImageBackground>
		</View>
	);
};

export default Hub;