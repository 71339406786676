import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { View, Text, Modal, Pressable, Animated, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { doc, getDoc, setDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from '../../firebase';
import { useUser } from '../../Widgets/UserContext';

const TutorialContext = createContext();

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const fontFamilies = {
 Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

// Initialize Firestore and other Firebase services
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage



export const useTutorial = () => useContext(TutorialContext);

export const TutorialProvider = ({ children }) => {
 const { currentUserData } = useUser();
 const [showTutorial, setShowTutorial] = useState(false);
 const [currentSteps, setCurrentSteps] = useState({});
 const [closedTutorials, setClosedTutorials] = useState({});
 const [activePopupId, setActivePopupId] = useState(null);
 const [isLoading, setIsLoading] = useState(true);
 const [completedCount, setCompletedCount] = useState(0);
 const [tutorialsToShow, setTutorialsToShow] = useState([]);

 // Define tutorial steps at the provider level
 const tutorialSteps = {
  // Hub Tutorials
  welcome: ["Welcome to MyTassel! Let's get you started. Hover over the dots to see tutorials. \n(turn off tutorials at any time in settings)"],
  profile: ["Click here to set up your profile."],
  announcement: ["Click here to create and access your announcement page."],
  registry: ["Click here to create and access your registry."],
  resources: ["Click here to access various resources."],
  // friends: ["Connect with friends and manage your friend requests here"],
  // settings: ["Customize your account settings and tutorial preferences"],
  
  // Announcement Tutorials
  eventsIntro: ["Welcome to your Announcements page! Here you can create and manage your events and announcements."],
  eventsWidget: [`Add as many widgets as you'd like, in whatever order you'd like, and feel free to select the same one more than once. Remember to click SAVE after you add each widget to save your changes.\n\n * Countdown - Track the days, hours, and minutes leading up to your big day\n\n * Text - Let friends and family know details about your celebration plans, what you're doing after graduation, etc.\n\nSlideshow - Share your favorite memories and photos \n\n* RSVP - Track the number of guests coming to your event \n\n* URL Preview - If you're celebrating outside of your home, add a link to your celebration location \n\n* Polaroids - Share your favorite memories and photos`],
  eventsSave: ["Remember to save your changes before leaving the page."],
  eventsBackground: ["Customize your page background (and registry background) and appearance here."],
  eventsPreview: ["Preview how visitors will see your page using this button."],
  
  // Registry Tutorials
  registryIntro: ["Welcome to your Registry! Create and manage your gift registry here."],
  registryCatalogue: ["Browse our catalogue to add items to your registry."],
  
  // Combined Card Tutorials
  combinedCardYearbook: ["Share messages and photos in the yearbook section."],
  combinedCardGoalTracker: ["Set and track your gift contribution goals here."],
  combinedCardRegistry: ["View or create the graduate's registry."],
  combinedCardShare: ["Share this announcement page with others."],
 };

 const markTutorialComplete = async (tutorialId) => {
  try {
   if (!currentUserData?.email) return;

   const userDocRef = doc(db, "users", currentUserData.email);
   const newCompletedTutorials = {
    ...closedTutorials,
    [tutorialId]: true
   };

   await updateDoc(userDocRef, {
    completedTutorials: newCompletedTutorials
   });

   setClosedTutorials(newCompletedTutorials);
   setCompletedCount(prev => prev + 1);
   setTutorialsToShow(prev => prev.filter(id => id !== tutorialId));

  } catch (error) {
   console.error("Error marking tutorial as complete:", error);
  }
 };

 useEffect(() => {
  const fetchCompletedTutorials = async () => {
   if (!currentUserData?.email) {
    setIsLoading(false);
    return;
   }

   try {
    const userDocRef = doc(db, "users", currentUserData.email);
    const userDocSnap = await getDoc(userDocRef);
    
    if (userDocSnap.exists()) {
     const userData = userDocSnap.data();
     const completedTutorials = userData.completedTutorials || {};
     
     const allTutorialIds = Object.keys(tutorialSteps);
     const remainingTutorials = allTutorialIds.filter(id => !completedTutorials[id]);

     setClosedTutorials(completedTutorials);
     setCompletedCount(Object.keys(completedTutorials).length);
     setTutorialsToShow(remainingTutorials);
    }
   } catch (error) {
    console.error("Error fetching completed tutorials:", error);
   } finally {
    setIsLoading(false);
   }
  };

  fetchCompletedTutorials();
 }, [currentUserData?.email]);

 return (
  <TutorialContext.Provider value={{
   showTutorial,
   setShowTutorial,
   currentSteps,
   setCurrentSteps,
   closedTutorials,
   setClosedTutorials,
   activePopupId,
   setActivePopupId,
   markTutorialComplete,
   isLoading,
   tutorialsToShow,
   setTutorialsToShow,
   tutorialSteps,
   completedCount
  }}>
   {children}
  </TutorialContext.Provider>
 );
};

export const TutorialPopup = ({
 id,
 steps,
 position,
 title,
 openDirection = 'bottomLeft',
 totalTutorials
}) => {
 const {
  showTutorial,
  closedTutorials,
  isLoading,
  tutorialsToShow,
  tutorialSteps,
  completedCount,
  markTutorialComplete,
  setActivePopupId,
  setClosedTutorials,
  setTutorialsToShow
 } = useTutorial();
 const [isPopupVisible, setIsPopupVisible] = useState(false);
 const hoverTimeoutRef = useRef(null);

 // Move useEffect to top level
 useEffect(() => {
  return () => {
   if (hoverTimeoutRef.current) {
    clearTimeout(hoverTimeoutRef.current);
   }
  };
 }, []);

 // Early return after hooks
 if (isLoading || !showTutorial || !tutorialsToShow.includes(id)) {
  return null;
 }

 const tutorialContent = Array.isArray(steps) ? steps : tutorialSteps[id];

 const handleNext = async () => {
  setClosedTutorials(prev => ({
   ...prev,
   [id]: true
  }));
  
  setTutorialsToShow(prev => prev.filter(tutorialId => tutorialId !== id));
  
  setIsPopupVisible(false);
  
  const currentIndex = tutorialsToShow.indexOf(id);
  if (currentIndex < tutorialsToShow.length - 1) {
   const nextTutorialId = tutorialsToShow[currentIndex + 1];
   setActivePopupId(nextTutorialId);
  }

  await markTutorialComplete(id);
 };

 const handleMouseEnter = () => {
  if (hoverTimeoutRef.current) {
   clearTimeout(hoverTimeoutRef.current);
  }
  setIsPopupVisible(true);
 };

 const handleMouseLeave = () => {
  hoverTimeoutRef.current = setTimeout(() => {
   setIsPopupVisible(false);
  }, 500);
 };

 const getPopupPosition = () => {
  const basePosition = {};
  
  // Handle vertical positioning
  if (openDirection.startsWith('top')) {
   basePosition.bottom = 25; // Position above the dot
  } else if (openDirection.startsWith('bottom')) {
   basePosition.top = 25; // Position below the dot
  }
  
  // Handle horizontal positioning
  if (openDirection.endsWith('Left')) {
   basePosition.right = 0; // Align right edge of popup with dot
  } else if (openDirection.endsWith('Right')) {
   basePosition.left = 0; // Align left edge of popup with dot
  }

  return basePosition;
 };

 return (
  <View
   style={[
    styles.tutorialContainer,
    position,
    { position: 'absolute' }
   ]}
   onMouseEnter={handleMouseEnter}
   onMouseLeave={handleMouseLeave}
  >
   <View style={[
    styles.tutorialDot,
    isPopupVisible && styles.dotActive
   ]}>
    <View style={styles.dot} />
   </View>

   {isPopupVisible && (
    <View style={[
     styles.popupContainer,
     getPopupPosition()
    ]}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >
     <Pressable 
      style={styles.closeButtonX} 
      onPress={() => setIsPopupVisible(false)}
     >
      <Text style={styles.closeButtonText}>×</Text>
     </Pressable>

     <Text style={styles.popupTitle}>{title}</Text>
     {tutorialContent.map((step, index) => (
      <Text key={index} style={styles.popupText}>{step}</Text>
     ))}

     <View style={styles.buttonContainer}>
      <Pressable 
       style={[styles.button, styles.nextButton]}
       onPress={handleNext}
      >
       <Text style={styles.buttonText}>
        {tutorialsToShow.indexOf(id) === tutorialsToShow.length - 1 ? 'Finish' : 'Okay'}
       </Text>
      </Pressable>
     </View>

     <Text style={styles.tutorialStepNumber}>
      {`${tutorialsToShow.indexOf(id) + 1}/${tutorialsToShow.length}`}
     </Text>
    </View>
   )}
  </View>
 );
};

const styles = StyleSheet.create({
 tutorialContainer: {
  position: 'absolute',
  zIndex: 10002,
  width: 17.5,
  height: 17.5,
 },
 tutorialDot: {
  width: '100%',
  height: '100%',
  borderRadius: 10,
  backgroundColor: '#fefde2',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.25,
  transition: 'opacity 0.3s ease',
 },
 dot: {
  width: 5,
  height: 5,
  backgroundColor: '#fefde2',
  opacity: 0.5,
 },
 dotActive: {
  opacity: 1,
 },
 popupContainer: {
  position: 'absolute',
  backgroundColor: '#fefde2',
  padding: 20,
  borderRadius: 10,
  width: 300,
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.25,
  shadowRadius: 4,
  zIndex: 10003,
 },
 popupTitle: {
  fontSize: 18,
  fontWeight: 'bold',
  color: '#4c956c',
  fontFamily: fontFamilies.Bodoni72Smallcaps,
  textAlign: 'center',
 },
 popupText: {
  fontSize: 14,
  color: '#666',
  fontFamily: fontFamilies.Bodoni72Smallcaps,
  marginBottom: 10,
  textAlign: 'center',
 },
 buttonContainer: {
  marginTop: 5,
  marginBottom: 5,
  flexDirection: 'row',
  justifyContent: 'center',
 },
 button: {
  backgroundColor: '#4c956c',
  padding: 10,
  borderRadius: 5,
  minWidth: 100,
 },
 nextButton: {
  backgroundColor: '#4c956c',
 },
 buttonText: {
  color: '#ffffff',
  textAlign: 'center',
  fontFamily: fontFamilies.Bodoni72Smallcaps,
  fontSize: 14,
 },
 closeButton: {
  backgroundColor: '#e74c3c',
 },
 closeButtonX: {
  position: 'absolute',
  top: 5,
  left: 5,
  backgroundColor: 'transparent',
  padding: 5,
 },
 closeButtonText: {
  fontSize: 16,
  color: 'grey',
 },
 tutorialStepNumber: {
  position: 'absolute',
  bottom: 2.5,
  left: 10,
  fontSize: 14,
  color: '#666',
  fontFamily: fontFamilies.Bodoni72Smallcaps,
  paddingTop: 10,
 },
});

export const initializeUserDocument = async (email) => {
 const userDocRef = doc(db, "users", email);
 const userDocSnap = await getDoc(userDocRef);

 if (!userDocSnap.exists()) {
  await setDoc(userDocRef, { showTutorial: true });
 }
};

export const checkAndAddTutorialSetting = async (email) => {
 const userDocRef = doc(db, "users", email);
 const userDocSnap = await getDoc(userDocRef);

 if (userDocSnap.exists()) {
  const userData = userDocSnap.data();
  if (userData.showTutorial === undefined) {
   await updateDoc(userDocRef, { showTutorial: true });
  }
 }
};

const getNextTutorialId = (currentId) => {
 const tutorialIds = Object.keys(tutorialSteps);
 const currentIndex = tutorialIds.indexOf(currentId);
 return tutorialIds[currentIndex + 1];
};

// Call this function when a user signs in
