import React, { useState, useEffect } from 'react';
import { View, Text, Modal, TouchableOpacity, TextInput, FlatList, StyleSheet, Image, ScrollView, Linking, Switch, Pressable, Dimensions } from 'react-native';
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc, deleteField, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useUser } from './UserContext';
import genericProfilePicture from '../assets/generic.jpg';
import PromptSignUp from './PromptSignUp';

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;


const Friends = React.forwardRef(({ isVisible, onClose, isFriendsListPrivate }, ref) => {
    const db = getFirestore();
    const auth = getAuth();
    const { currentUserData, setCurrentUserData } = useUser();

    // State hooks
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [suggestedFriends, setSuggestedFriends] = useState([]);
    const [currentFriends, setCurrentFriends] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [activeTab, setActiveTab] = useState('suggested');
    const [userCache, setUserCache] = useState([]);
    const [isPrivate, setIsPrivate] = useState(isFriendsListPrivate);
    const [friendRequests, setFriendRequests] = useState([]);
    const [showSignupPopup, setShowSignupPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasSentRequestList, setHasSentRequestList] = useState({});
    const [showPromptSignUp, setShowPromptSignUp] = useState(false);
    const [showPrivacyWarning, setShowPrivacyWarning] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        setIsPrivate(isFriendsListPrivate);
    }, [isFriendsListPrivate]);

    useEffect(() => {
        if (!currentUserData && isVisible) {
            setShowPromptSignUp(true);
        }
    }, [isVisible, currentUserData]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchUsers = async () => {
            try {
                const usersRef = collection(db, "users");
                const querySnapshot = await getDocs(usersRef);
                const users = querySnapshot.docs
                    .map(doc => ({ ...doc.data(), email: doc.id }))
                    .filter(user => user.uid !== currentUserData?.uid);
                
                setUserCache(users);
                
                // Run cleanup after fetching users
                await cleanupNonGraduateConnections(users);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, [auth.currentUser, currentUserData]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchCurrentFriends = async () => {
            setIsLoading(true);
            try {
                const userEmail = auth.currentUser.email; // Ensure we get the email property
                if (!userEmail) {
                    console.error("User email is not available");
                    return;
                }

                const userDoc = await getDoc(doc(db, "users", userEmail));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const friendsData = userData.friends || {};
                    setCurrentFriends([
                        ...Object.keys(friendsData.following || {}).filter(uid => uid !== currentUserData?.uid),
                        ...Object.keys(friendsData.requestsSent || {}).filter(uid => uid !== currentUserData?.uid)
                    ]);
                    setFollowers(Object.values(friendsData.followers || {}).filter(follower => follower.uid !== currentUserData?.uid));
                    setFriendRequests(Object.values(friendsData.requests || {}).filter(request => request.uid !== currentUserData?.uid));
                    setHasSentRequestList(Object.keys(friendsData.requestsSent || {}).filter(requestSent => requestSent.uid !== currentUserData?.uid));
                }
            } catch (error) {
                console.error("Error fetching current friends and followers:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isVisible) {
            fetchCurrentFriends();
        }
    }, [isVisible, currentUserData, userCache]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchPrivacySetting = async () => {
            try {
                const userEmail = auth.currentUser.email;
                if (!userEmail) {
                    console.error("User email is not available");
                    return;
                }

                const userDoc = await getDoc(doc(db, "users", userEmail));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsPrivate(userData.isFriendsListPrivate || false);
                }
            } catch (error) {
                console.warn("Error fetching privacy setting:", error);
            }
        };

        fetchPrivacySetting();
    }, [auth.currentUser, currentUserData]); // Ensure this effect runs when currentUserData changes

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        if (searchText === '') {
            setSearchResults([]);
        } else {
            const results = userCache.filter(user => {
                const firstName = user.firstName?.toLowerCase() || '';
                const lastName = user.lastName?.toLowerCase() || '';
                const uid = user.uid?.toLowerCase() || '';
                const institutionName = user.organization?.name?.toLowerCase() || '';
                const institutionCode = user.organization?.code?.toLowerCase() || '';
                
                return user.accountType === 'Graduate' && 
                    user.uid !== currentUserData?.uid && (
                        firstName.includes(searchText.toLowerCase()) ||
                        lastName.includes(searchText.toLowerCase()) ||
                        uid.includes(searchText.toLowerCase()) ||
                        institutionName.includes(searchText.toLowerCase()) ||
                        institutionCode.includes(searchText.toLowerCase())
                    );
            });
            setSearchResults(results);
        }
    }, [searchText, userCache, currentFriends, hasSentRequestList, friendRequests]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData) {
            return;
        }

        const fetchSuggestedFriends = () => {
            const currentUser = userCache.find(user => user.uid === currentUserData.uid);
            if (!currentUserData) {
                return;
            }

            const sameInstitution = userCache.filter(user => 
                user.accountType === 'Graduate' && 
                user.organization?.name === currentUserData.organization?.name && 
                user.email !== currentUserData.email && 
                !currentFriends.includes(user.uid)
            );

            const similarFriends = userCache.filter(user => {
                const friendIds = Object.keys(user.friends?.following || {});
                return user.accountType === 'Graduate' && 
                    friendIds.some(friendId => currentFriends.includes(friendId)) && 
                    user.email !== currentUserData.email && 
                    !currentFriends.includes(user.uid);
            });

            const randomUsers = userCache.filter(user => 
                user.accountType === 'Graduate' && 
                user.email !== currentUserData.email && 
                !currentFriends.includes(user.uid)
            );

            const suggestions = [...new Set([...sameInstitution, ...similarFriends, ...randomUsers])]
                .filter(user => user.uid !== currentUserData?.uid)
                .filter(user => !currentUserData?.friends?.following?.hasOwnProperty(user.uid))
                .slice(0, 5);

            setSuggestedFriends(suggestions);
        };

        fetchSuggestedFriends();
    }, [userCache, currentFriends, currentUserData]);

    // Add this helper function to refresh all friend-related data
    const refreshFriendData = async () => {
        console.log('Refreshing all friend data...');
        if (!auth.currentUser?.email) return;
        
        try {
            const userDoc = await getDoc(doc(db, "users", auth.currentUser.email));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const friendsData = userData.friends || {};
                
                // Fetch complete data for each follower
                const followersPromises = Object.values(friendsData.followers || {}).map(async follower => {
                    const followerDoc = await getDoc(doc(db, "users", follower.email));
                    if (followerDoc.exists()) {
                        const followerData = followerDoc.data();
                        return {
                            ...follower,
                            isFriendsListPrivate: followerData.isFriendsListPrivate || false
                        };
                    }
                    return follower;
                });

                const updatedFollowers = await Promise.all(followersPromises);
                console.log('Updated followers with privacy settings:', updatedFollowers);
                
                setFollowers(updatedFollowers);
                setCurrentFriends(Object.keys(friendsData.following || {}));
                setFriendRequests(Object.values(friendsData.requests || {}));
                setHasSentRequestList(Object.keys(friendsData.requestsSent || {}));
            }
        } catch (error) {
            console.error("Error refreshing friend data:", error);
        }
    };

    const addFriend = async (email) => {
        setIsUpdating(true);
        try {
            console.log('Adding friend:', email);
            if (!auth.currentUser || !currentUserData) {
                console.error("User is not authenticated or current user data is not available");
                return;
            }

            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);
            const friendSnapshot = await getDoc(friendDoc);

            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendData = friendSnapshot.data();
            console.log('Friend data:', friendData);

            // Firestore updates
            const updates = {
                [`friends.following.${friendData.uid}`]: {
                    email: friendData.email,
                    name: `${friendData.firstName || ""} ${friendData.lastName || ""}`,
                    profilePictureURL: friendData.profilePictureURL || "",
                    uid: friendData.uid
                }
            };

            await Promise.all([
                // Update current user's following list
                updateDoc(userDoc, updates),
                // Update friend's followers list
                updateDoc(friendDoc, {
                    [`friends.followers.${currentUserData.uid}`]: {
                        email: currentUserData.email,
                        name: `${currentUserData.firstName || ""} ${currentUserData.lastName || ""}`,
                        profilePictureURL: currentUserData.profilePictureURL || "",
                        uid: currentUserData.uid
                    }
                })
            ]);

            // Immediate UI updates using the same friendData
            setCurrentFriends(prev => [...prev, friendData.uid]);
            setSuggestedFriends(prev => prev.filter(user => user.uid !== friendData.uid));
            setSearchResults(prev => prev.map(user => 
                user.uid === friendData.uid 
                    ? { ...user, isFollowing: true, hasSentRequest: false }
                    : user
            ));

            // Force UI refresh
            setActiveTab(prev => prev);

            // Force refresh of followers data
            await refreshFriendData();
            
            // Update local state immediately
            setCurrentFriends(prev => [...prev, friendData.uid]);
            
            // Force re-render of the current view
            setActiveTab(prev => prev);

        } catch (error) {
            console.error("Error adding friend:", error);
        } finally {
            setIsUpdating(false);
        }
    };

    const unfollowFriend = async (email) => {
        setIsUpdating(true);
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);
            const friendSnapshot = await getDoc(friendDoc);

            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendData = friendSnapshot.data();

            // Batch updates for both users
            await Promise.all([
                // Remove from current user's following
                updateDoc(userDoc, {
                    [`friends.following.${friendData.uid}`]: deleteField()
                }),
                // Remove from friend's followers
                updateDoc(friendDoc, {
                    [`friends.followers.${currentUserData.uid}`]: deleteField()
                })
            ]);

            // Immediate UI updates
            setCurrentFriends(prev => prev.filter(uid => uid !== friendData.uid));
            setSuggestedFriends(prev => {
                if (!prev.some(user => user.uid === friendData.uid)) {
                    return [...prev, { ...friendData, isFollowing: false }];
                }
                return prev;
            });
            setSearchResults(prev => prev.map(user => 
                user.uid === friendData.uid 
                    ? { ...user, isFollowing: false }
                    : user
            ));

            // Force refresh all friend data
            await refreshFriendData();

        } catch (error) {
            console.error("Error unfollowing friend:", error);
        } finally {
            setIsUpdating(false);
        }
    };

    const sendFriendRequest = async (email) => {
        setIsUpdating(true);
        try {
            console.log('Sending friend request:', email);
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);
            const friendSnapshot = await getDoc(friendDoc);

            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendData = friendSnapshot.data();
            console.log('Friend data:', friendData);

            await Promise.all([
                // Add request to friend's requests list
                updateDoc(friendDoc, {
                    [`friends.requests.${currentUserData.uid}`]: {
                        email: currentUserData.email,
                        name: `${currentUserData.firstName || ""} ${currentUserData.lastName || ""}`,
                        profilePictureURL: currentUserData.profilePictureURL || "",
                        uid: currentUserData.uid
                    }
                }),
                // Add to current user's requestsSent list
                updateDoc(userDoc, {
                    [`friends.requestsSent.${friendData.uid}`]: true
                })
            ]);

            // Immediate UI updates using the same friendData
            setHasSentRequestList(prev => [...prev, friendData.uid]);
            setSuggestedFriends(prev => prev.map(user => 
                user.uid === friendData.uid 
                    ? { ...user, hasSentRequest: true }
                    : user
            ));
            setSearchResults(prev => prev.map(user => 
                user.uid === friendData.uid 
                    ? { ...user, hasSentRequest: true }
                    : user
            ));

            // Force UI refresh
            setActiveTab(prev => prev);

            // Force refresh of followers data
            await refreshFriendData();
            
            // Update local state immediately
            setHasSentRequestList(prev => [...prev, friendData.uid]);
            
            // Force re-render of the current view
            setActiveTab(prev => prev);

        } catch (error) {
            console.error("Error sending friend request:", error);
        } finally {
            setIsUpdating(false);
        }
    };

    const cancelFriendRequest = async (email) => {
        setIsUpdating(true);
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", email);
            const friendSnapshot = await getDoc(friendDoc);

            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendData = friendSnapshot.data();

            // Batch updates for both users
            await Promise.all([
                // Remove from current user's requestsSent
                updateDoc(userDoc, {
                    [`friends.requestsSent.${friendData.uid}`]: deleteField()
                }),
                // Remove from friend's requests
                updateDoc(friendDoc, {
                    [`friends.requests.${currentUserData.uid}`]: deleteField()
                })
            ]);

            // Immediate UI updates
            setHasSentRequestList(prev => prev.filter(uid => uid !== friendData.uid));
            setSuggestedFriends(prev => prev.map(user => 
                user.uid === friendData.uid 
                    ? { ...user, hasSentRequest: false }
                    : user
            ));
            setSearchResults(prev => prev.map(user => 
                user.uid === friendData.uid 
                    ? { ...user, hasSentRequest: false }
                    : user
            ));

            // Force refresh all friend data
            await refreshFriendData();

        } catch (error) {
            console.error("Error canceling friend request:", error);
        } finally {
            setIsUpdating(false);
        }
    };

    const acceptFriendRequest = async (friendEmail) => {
        setIsUpdating(true);
        try {
            console.log('Accepting friend request from:', friendEmail);
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", friendEmail);
            const friendSnapshot = await getDoc(friendDoc);

            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendData = friendSnapshot.data();
            console.log('Friend data:', friendData);

            // Include isFriendsListPrivate in the follower data
            const newFollower = {
                uid: friendData.uid,
                email: friendData.email,
                name: `${friendData.firstName || ""} ${friendData.lastName || ""}`,
                profilePictureURL: friendData.profilePictureURL || "",
                isFriendsListPrivate: friendData.isFriendsListPrivate || false
            };

            // Update Firestore with the enhanced follower data
            await Promise.all([
                updateDoc(userDoc, {
                    [`friends.followers.${friendData.uid}`]: newFollower,
                    [`friends.requests.${friendData.uid}`]: deleteField()
                }),
                updateDoc(friendDoc, {
                    [`friends.following.${currentUserData.uid}`]: {
                        email: currentUserData.email,
                        name: `${currentUserData.firstName || ""} ${currentUserData.lastName || ""}`,
                        profilePictureURL: currentUserData.profilePictureURL || "",
                        uid: currentUserData.uid
                    },
                    [`friends.requestsSent.${currentUserData.uid}`]: deleteField()
                })
            ]);

            console.log('Friend request accepted and databases updated');
            
            // Force refresh all friend data
            await refreshFriendData();
            
            // Immediate UI updates with the enhanced follower data
            setFollowers(prev => {
                const isAlreadyFollower = prev.some(f => f.uid === friendData.uid);
                if (!isAlreadyFollower) {
                    return [...prev, newFollower];
                }
                return prev;
            });

            // Force UI update
            setActiveTab(prev => prev);

        } catch (error) {
            console.error("Error accepting friend request:", error);
        } finally {
            setIsUpdating(false);
        }
    };

    const declineFriendRequest = async (friendEmail) => {
        console.log('Declining friend request from:', friendEmail);
        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const friendDoc = doc(db, "users", friendEmail);
            const friendSnapshot = await getDoc(friendDoc);

            if (!friendSnapshot.exists()) {
                console.error("Friend not found");
                return;
            }

            const friendData = friendSnapshot.data();
            console.log('Friend data:', friendData);

            // Remove request from current user
            await updateDoc(userDoc, {
                [`friends.requests.${friendData.uid}`]: deleteField()
            });
            console.log('Removed request from current user');

            // Remove requestSent from friend
            await updateDoc(friendDoc, {
                [`friends.requestsSent.${currentUserData.uid}`]: deleteField()
            });
            console.log('Removed requestSent from friend');

            // Update local state
            setFriendRequests(prev => {
                console.log('Previous requests:', prev);
                const updated = prev.filter(request => request.uid !== friendData.uid);
                console.log('Updated requests:', updated);
                return updated;
            });

            // Force refresh of all friend-related data
            await refreshFriendData();
            console.log('Refreshed friend data');

        } catch (error) {
            console.error("Error declining friend request:", error);
        }
    };

    const fetchPrivacySetting = async () => {
        if (!currentUserData || !currentUserData.email) {
            console.error("User is not authenticated or email is not available");
            return;
        }

        const userDoc = await getDoc(doc(db, "users", currentUserData.email));
        if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsPrivate(userData.isFriendsListPrivate || false);
        } else {
            setIsPrivate(false); // Default to false if the field is not found
        }
    };

    useEffect(() => {
        fetchPrivacySetting();
    }, [currentUserData]);

    const updatePrivacySetting = async (isPrivate) => {
        const generateUIC = () => {
            return Math.random().toString(36).substr(2, 9); // Simple UIC generation logic
        };

        try {
            if (!currentUserData || !currentUserData.email) {
                console.error("User is not authenticated or email is not available");
                return;
            }

            const userDoc = doc(db, "users", currentUserData.email);
            const updates = {
                isFriendsListPrivate: isPrivate
            };

            // Only generate new UIC when switching from public to private and no UIC exists
            if (isPrivate && !currentUserData.uic) {
                updates.uic = generateUIC();
            }

            await updateDoc(userDoc, updates);
            setIsPrivate(isPrivate);
        } catch (error) {
            console.error("Error updating privacy setting:", error);
        }
    };

    const handlePrivacyToggle = () => {
        if (isPrivate) {
            setShowPrivacyWarning(true);
        } else {
            setIsPrivate(true);
            updatePrivacySetting(true);
        }
    };

    const confirmPrivacyToggle = () => {
        setIsPrivate(false);
        setShowPrivacyWarning(false);
        updatePrivacySetting(false);
    };

    const renderFriendItem = ({ item }) => {
        if (!item) return null;

        const UID = item.uid;
        const isFollowing = currentFriends.includes(UID);
        const isFollower = followers.some(follower => follower.uid === UID);
        const hasSentRequest = hasSentRequestList.includes(UID);
        const canViewPages = !item.isFriendsListPrivate || isFollowing;

        // Log the state for debugging
        console.log('Rendering follower item:', {
            uid: UID,
            name: item.name,
            isFollowing,
            isFollower,
            hasSentRequest,
            isFriendsListPrivate: item.isFriendsListPrivate
        });

        // Check if the user is the current user
        if (item.email === auth.currentUser?.email) return null;

        return (
            <View style={styles.friendItem}>
                <Image 
                    source={{ uri: item.profilePictureURL || genericProfilePicture }} 
                    style={styles.profilePicture} 
                />
                <Text>{item.firstName || item.name || 'Unknown'} {item.lastName || ''}</Text>
                <View style={styles.buttonContainer}>
                    {/* Show page buttons if following */}
                    {isFollowing && (
                        <>
                            {item.pages?.registry && (
                                <TouchableOpacity 
                                    onPress={() => Linking.openURL(item.pages.registry)} 
                                    style={styles.visitButton}
                                >
                                    <Text style={styles.visitButtonText}>Registry Page</Text>
                                </TouchableOpacity>
                            )}
                            {item.pages?.events && (
                                <TouchableOpacity 
                                    onPress={() => Linking.openURL(item.pages.events)} 
                                    style={styles.visitButton}
                                >
                                    <Text style={styles.visitButtonText}>Announcement Page</Text>
                                </TouchableOpacity>
                            )}
                        </>
                    )}

                    {/* Friend action buttons for followers tab */}
                    {activeTab === 'followers' ? (
                        isFollowing ? (
                            <TouchableOpacity 
                                onPress={() => unfollowFriend(item.email)} 
                                style={[styles.unfollowButton, isUpdating && styles.buttonDisabled]}
                                disabled={isUpdating}
                            >
                                <Text style={styles.unfollowButtonText}>
                                    {isUpdating ? 'Updating...' : 'Unfollow'}
                                </Text>
                            </TouchableOpacity>
                        ) : hasSentRequest ? (
                            <TouchableOpacity 
                                onPress={() => cancelFriendRequest(item.email)} 
                                style={[styles.cancelRequestButton, isUpdating && styles.buttonDisabled]}
                                disabled={isUpdating}
                            >
                                <Text style={styles.cancelRequestButtonText}>
                                    {isUpdating ? 'Updating...' : 'Cancel Request'}
                                </Text>
                            </TouchableOpacity>
                        ) : item.isFriendsListPrivate ? (
                            <TouchableOpacity 
                                onPress={() => sendFriendRequest(item.email)} 
                                style={[styles.addButton, isUpdating && styles.buttonDisabled]}
                                disabled={isUpdating}
                            >
                                <Text style={styles.addButtonText}>
                                    {isUpdating ? 'Updating...' : 'Send Friend Request'}
                                </Text>
                            </TouchableOpacity>
                        ) : (
                            <TouchableOpacity 
                                onPress={() => addFriend(item.email)} 
                                style={[styles.addButton, isUpdating && styles.buttonDisabled]}
                                disabled={isUpdating}
                            >
                                <Text style={styles.addButtonText}>
                                    {isUpdating ? 'Updating...' : 'Follow Back'}
                                </Text>
                            </TouchableOpacity>
                        )
                    ) : (
                        // Regular friend action buttons for other tabs
                        isFollowing ? (
                            <TouchableOpacity 
                                onPress={() => unfollowFriend(item.email)} 
                                style={styles.unfollowButton}
                            >
                                <Text style={styles.unfollowButtonText}>Unfollow</Text>
                            </TouchableOpacity>
                        ) : hasSentRequest ? (
                            <TouchableOpacity 
                                onPress={() => cancelFriendRequest(item.email)} 
                                style={styles.cancelRequestButton}
                            >
                                <Text style={styles.cancelRequestButtonText}>Cancel Request</Text>
                            </TouchableOpacity>
                        ) : item.isFriendsListPrivate ? (
                            <TouchableOpacity 
                                onPress={() => sendFriendRequest(item.email)} 
                                style={[styles.addButton, isUpdating && styles.buttonDisabled]}
                                disabled={isUpdating}
                            >
                                <Text style={styles.addButtonText}>
                                    {isUpdating ? 'Updating...' : 'Send Friend Request'}
                                </Text>
                            </TouchableOpacity>
                        ) : (
                            <TouchableOpacity 
                                onPress={() => addFriend(item.email)} 
                                style={[styles.addButton, isUpdating && styles.buttonDisabled]}
                                disabled={isUpdating}
                            >
                                <Text style={styles.addButtonText}>
                                    {isUpdating ? 'Updating...' : 'Add Friend'}
                                </Text>
                            </TouchableOpacity>
                        )
                    )}
                </View>
            </View>
        );
    };

    const renderRequestItem = ({ item }) => {
        if (!item) return null;
        const UID = item.uid;

        return (
            <View style={styles.friendItem}>
                <Image source={{ uri: item.profilePictureURL || genericProfilePicture }} style={styles.profilePicture} />
                <Text>{item.name || 'Unknown User'}</Text>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity onPress={() => acceptFriendRequest(item.email)} style={styles.acceptButton}>
                        <Text style={styles.acceptButtonText}>Accept</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => declineFriendRequest(item.email)} style={styles.declineButton}>
                        <Text style={styles.declineButtonText}>Decline</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    const updateExistingUsers = async () => {
        const usersRef = collection(db, "users");
        const querySnapshot = await getDocs(usersRef);
        querySnapshot.forEach(async (doc) => {
            const userData = doc.data();
            let friendsData = userData.friends || {};

            if (!friendsData.following || !friendsData.followers || !friendsData.requests) {
                const following = friendsData.following ? Object.keys(friendsData.following) : [];
                const followers = friendsData.followers ? Object.keys(friendsData.followers) : [];
                const requests = friendsData.requests ? Object.keys(friendsData.requests) : [];

                const newFriendsData = {
                    following: following.reduce((acc, email) => ({ ...acc, [email]: true }), {}),
                    followers: followers.reduce((acc, email) => ({ ...acc, [email]: true }), {}),
                    requests: requests.reduce((acc, email) => ({ ...acc, [email]: true }), {})
                };

                await updateDoc(doc.ref, {
                    friends: newFriendsData
                });
            }
        });
    };

    useEffect(() => {
        updateExistingUsers();
    }, []);

    useEffect(() => {
        const fetchPrivacySetting = async () => {
            if (!auth.currentUser) {
                console.error("User is not authenticated");
                return;
            }

            try {
                const userEmail = auth.currentUser.email;
                const userDoc = await getDoc(doc(db, "users", userEmail));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsPrivate(userData.isFriendsListPrivate || false);
                }
            } catch (error) {
                console.error("Error fetching privacy setting:", error);
            }
        };

        if (isVisible) {
            fetchPrivacySetting();
        }
    }, [isVisible, auth.currentUser]);

    useEffect(() => {
        if (!auth.currentUser || !currentUserData || !isVisible) return;

        console.log('Setting up real-time friend data listener');
        const userEmail = auth.currentUser.email;
        const userDocRef = doc(db, "users", userEmail);

        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                const friendsData = userData.friends || {};
                console.log('Real-time update received:', friendsData);

                // Immediately update all states
                setCurrentFriends(Object.keys(friendsData.following || {}));
                setFollowers(Object.values(friendsData.followers || {}));
                setFriendRequests(Object.values(friendsData.requests || {}));
                setHasSentRequestList(Object.keys(friendsData.requestsSent || {}));

                // Update search results
                setSearchResults(prev => prev.map(user => ({
                    ...user,
                    isFollowing: Object.keys(friendsData.following || {}).includes(user.uid),
                    hasSentRequest: Object.keys(friendsData.requestsSent || {}).includes(user.uid)
                })));

                // Update suggested friends
                setSuggestedFriends(prev => prev.map(user => ({
                    ...user,
                    isFollowing: Object.keys(friendsData.following || {}).includes(user.uid),
                    hasSentRequest: Object.keys(friendsData.requestsSent || {}).includes(user.uid)
                })));

                // Force UI update
                setActiveTab(prev => prev);
            }
        });

        return () => unsubscribe();
    }, [auth.currentUser, currentUserData, isVisible]);

    // Add this cleanup function
    const cleanupNonGraduateConnections = async (userCache) => {
        console.log('Cleaning up non-graduate connections...');
        if (!auth.currentUser?.email || !currentUserData) return;

        try {
            const userDoc = doc(db, "users", auth.currentUser.email);
            const userData = (await getDoc(userDoc)).data();
            const friendsData = userData.friends || {};
            
            // Get lists of current connections
            const following = friendsData.following || {};
            const followers = friendsData.followers || {};
            const requests = friendsData.requests || {};
            const requestsSent = friendsData.requestsSent || {};

            let updates = {};
            let needsUpdate = false;

            // Check following list
            for (const [uid, data] of Object.entries(following)) {
                const userInCache = userCache.find(u => u.uid === uid);
                if (!userInCache || userInCache.accountType !== 'Graduate') {
                    console.log(`Removing non-graduate user from following: ${uid}`);
                    updates[`friends.following.${uid}`] = deleteField();
                    needsUpdate = true;
                }
            }

            // Check followers list
            for (const [uid, data] of Object.entries(followers)) {
                const userInCache = userCache.find(u => u.uid === uid);
                if (!userInCache || userInCache.accountType !== 'Graduate') {
                    console.log(`Removing non-graduate user from followers: ${uid}`);
                    updates[`friends.followers.${uid}`] = deleteField();
                    needsUpdate = true;
                }
            }

            // Check requests list
            for (const [uid, data] of Object.entries(requests)) {
                const userInCache = userCache.find(u => u.uid === uid);
                if (!userInCache || userInCache.accountType !== 'Graduate') {
                    console.log(`Removing non-graduate user from requests: ${uid}`);
                    updates[`friends.requests.${uid}`] = deleteField();
                    needsUpdate = true;
                }
            }

            // Check requestsSent list
            for (const [uid] of Object.entries(requestsSent)) {
                const userInCache = userCache.find(u => u.uid === uid);
                if (!userInCache || userInCache.accountType !== 'Graduate') {
                    console.log(`Removing non-graduate user from requestsSent: ${uid}`);
                    updates[`friends.requestsSent.${uid}`] = deleteField();
                    needsUpdate = true;
                }
            }

            // Update Firestore if needed
            if (needsUpdate) {
                console.log('Updating Firestore with cleaned up connections:', updates);
                await updateDoc(userDoc, updates);
                await refreshFriendData(); // Refresh local state after cleanup
            }

        } catch (error) {
            console.error("Error cleaning up non-graduate connections:", error);
        }
    };

    // Add real-time listener for friend requests
    useEffect(() => {
        if (!auth.currentUser || !currentUserData) return;

        console.log('Setting up real-time friend requests listener');
        const userEmail = auth.currentUser.email;
        const userDocRef = doc(db, "users", userEmail);

        const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                const friendsData = userData.friends || {};
                console.log('Real-time update received for friend requests:', friendsData);

                // Update all friend-related states
                setCurrentFriends(Object.keys(friendsData.following || {}));
                setFollowers(Object.values(friendsData.followers || {}));
                setFriendRequests(Object.values(friendsData.requests || {}));
                setHasSentRequestList(Object.keys(friendsData.requestsSent || {}));

                // Force re-render of the current view
                setActiveTab(prev => prev);
            }
        });

        return () => {
            console.log('Cleaning up friend requests listener');
            unsubscribe();
        };
    }, [auth.currentUser, currentUserData]);

    // Add this helper function to refresh the following list specifically
    const refreshFollowingList = async () => {
        console.log('Refreshing following list...');
        if (!auth.currentUser?.email) return;
        
        try {
            const userDoc = await getDoc(doc(db, "users", auth.currentUser.email));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const following = userData.friends?.following || {};
                
                // Update following list with only graduate users
                const graduateFollowing = Object.keys(following).filter(uid => 
                    userCache.some(user => user.uid === uid && user.accountType === 'Graduate')
                );
                
                setCurrentFriends(graduateFollowing);
                console.log('Updated following list:', graduateFollowing);
            }
        } catch (error) {
            console.error("Error refreshing following list:", error);
        }
    };

    // Add this effect to fetch data when modal opens
    useEffect(() => {
        if (isVisible) {
            console.log('Modal opened - fetching fresh friend data');
            refreshFriendData();
        }
    }, [isVisible]);

    const mapFollowersData = () => {
        return followers.map(follower => {
            const userCacheData = userCache.find(user => user.uid === follower.uid);
            return {
                ...follower,
                pages: userCacheData?.pages || {},
                isFriendsListPrivate: userCacheData?.isFriendsListPrivate || false
            };
        });
    };

    return (
        <>
            <PromptSignUp isVisible={showPromptSignUp} onClose={() => setShowPromptSignUp(false)} redirectPage="Friends" />
            <Modal animationType="slide" transparent={true} visible={isVisible && !showPromptSignUp} onRequestClose={onClose}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={styles.header}>
                            <>
                                <Image source={{ uri: currentUserData?.profilePictureURL || genericProfilePicture }} style={styles.headerImage} />
                                <Text style={styles.modalText}>Friends</Text>
                            </>
                            <View style={styles.privacyToggle}>
                                <Text style={styles.privacyToggleText}>
                                    {isPrivate ? 'Private' : 'Public'}
                                </Text>
                                <Switch
                                    value={isPrivate}
                                    onValueChange={handlePrivacyToggle}
                                />
                            </View>
                        </View>
                        <TextInput
                            style={styles.input}
                            onChangeText={(text) => {
                                setSearchText(text);
                                setActiveTab('search');
                            }}
                            value={searchText}
                            placeholder="Search by name, UID, institution name, or code"
                        />
                        <View style={styles.tabContainer}>
                            <TouchableOpacity onPress={() => setActiveTab('suggested')} style={activeTab === 'suggested' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Suggested</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setActiveTab('following')} style={activeTab === 'following' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Following</Text>       
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setActiveTab('followers')} style={activeTab === 'followers' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Followers</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setActiveTab('requests')} style={activeTab === 'requests' ? styles.activeTab : styles.inactiveTab}>
                                <Text style={styles.tabText}>Requests</Text>
                            </TouchableOpacity>
                        </View>
                        <ScrollView style={styles.scrollView}>
                            {isLoading ? (
                                <Text>Loading...</Text>
                            ) : (
                                <>
                                    {searchResults.length > 0 && (
                                        <>
                                            <Text style={styles.dividerText}>Search Results</Text>
                                            <FlatList
                                                data={searchResults}
                                                renderItem={renderFriendItem}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'suggested' && (
                                        <>
                                            <Text style={styles.dividerText}>Suggested Friends</Text>
                                            <FlatList
                                                data={suggestedFriends}
                                                renderItem={renderFriendItem}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'following' && (
                                        <>
                                            <Text style={styles.dividerText}>Following</Text>
                                            <FlatList
                                                data={currentFriends.map(uid => userCache.find(user => user.uid === uid)).filter(Boolean)}
                                                renderItem={({ item }) => renderFriendItem({ item })}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                    {activeTab === 'followers' && (
                                        <>
                                            <Text style={styles.dividerText}>Followers</Text>
                                            <FlatList
                                                data={mapFollowersData()}
                                                renderItem={renderFriendItem}
                                                keyExtractor={(item) => item.uid}
                                                extraData={[currentFriends, hasSentRequestList, isUpdating]} // Add this to force re-render on updates
                                            />
                                        </>
                                    )}
                                    {activeTab === 'requests' && (
                                        <>
                                            <Text style={styles.dividerText}>Friend Requests</Text>
                                            <FlatList
                                                data={friendRequests}
                                                renderItem={renderRequestItem}
                                                keyExtractor={(item) => item.email}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </ScrollView>
                        <TouchableOpacity style={[styles.button, styles.buttonClose]} onPress={onClose}>
                            <Text style={styles.textStyle}>Close</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
            <Modal transparent={true} animationType="slide" visible={showPrivacyWarning} onRequestClose={() => setShowPrivacyWarning(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Privacy Warning</Text>
                        <Text style={styles.warningText}>Making your page public will share your info with anyone, including your address if you create a registry.</Text>
                        <Pressable style={styles.modalButton} onPress={confirmPrivacyToggle}>
                            <Text style={styles.modalButtonText}>I Understand</Text>
                        </Pressable>
                        <Pressable style={styles.closeModalButton} onPress={() => setShowPrivacyWarning(false)}>
                            <Text style={styles.modalButtonText}>Cancel</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
        </>
    );
});

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        flex: 1,
        width: '90%',
        margin: 20,
        backgroundColor: '#fefde2',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
        elevation: 5,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 5
    },
    headerImage: {
        width: 55,
        height: 55,
        borderRadius: 100,
    },
    modalText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    input: {
        width: '100%',
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
    },
    tabContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    activeTab: {
        flex: 1,
        padding: 10,
        backgroundColor: '#4CAF50',
        alignItems: 'center',
        borderRadius: 5,
        marginRight: isMobile ? 5 : 10,
    },
    inactiveTab: {
        flex: 1,
        padding: 10,
        backgroundColor: '#e0e0e0',
        alignItems: 'center',
        borderRadius: 5,
        marginRight: isMobile ? 5 : 10,
    },
    tabText: {
        fontSize: isMobile ? 10 : 16,
        color: 'white'
    },
    scrollView: {
        width: '100%',
    },
    friendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    profilePicture: {
        width: isMobile ? 30 : 40,
        height: isMobile ? 30 : 40,
        borderRadius: 20,
        marginRight: 10,
    },
    addButton: {
        marginLeft: 'auto',
        backgroundColor: '#4CAF50',
        padding: 5,
        borderRadius: 5,
    },
    addButtonText: {
        fontSize: isMobile ? 10 : 16,
        color: 'white',
    },
    unfollowButton: {
        marginLeft: 'auto',
        backgroundColor: '#FF0000',
        padding: 5,
        borderRadius: 5,
    },
    unfollowButtonText: {
        color: 'white',
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
    },
    buttonClose: {
        backgroundColor: 'red',
        marginTop: 20,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    dividerText: {
        fontSize: 16,
        fontWeight: 'bold',
        marginVertical: 10,
        textAlign: 'center',
    },
    visitButton: {
        backgroundColor: '#2196F3',
        padding: 5,
        borderRadius: 5,
        marginRight: isMobile ? 0 : 10, // Remove right margin on mobile
        marginBottom: isMobile ? 5 : 0, // Add bottom margin on mobile
    },
    visitButtonText: {
        color: 'white',
        fontSize: isMobile ? 10 : 16,
        textAlign: 'center', // Center text
    },
    privacyToggle: {
        backgroundColor: '#FF9800',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        marginBottom: 10,
    },
    privacyToggleText: {
        color: 'white',
        fontWeight: 'bold',
    },
    buttonContainer: {
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        justifyContent: isMobile ? 'center' : 'flex-start', // Center buttons on mobile
    },
    buttonSignUp: {
        backgroundColor: 'green',
        marginTop: 20,
    },
    cancelRequestButton: {
        marginLeft: 'auto',
        backgroundColor: '#FF0000',
        padding: 5,
        borderRadius: 5,
    },
    cancelRequestButtonText: {
        color: 'white',
    },
    acceptButton: {
        marginLeft: 'auto',
        backgroundColor: '#4CAF50',
        padding: 5,
        borderRadius: 5,
    },
    acceptButtonText: {
        color: 'white',
    },
    declineButton: {
        marginLeft: 10,
        backgroundColor: '#FF0000',
        padding: 5,
        borderRadius: 5,
    },
    declineButtonText: {
        color: 'white',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fefde2',
        padding: 20,
        borderRadius: 10,
        width: '80%',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    warningText: {
        marginBottom: 20,
    },
    modalButton: {
        backgroundColor: '#4CAF50',
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
    },
    closeModalButton: {
        backgroundColor: '#FF0000',
        padding: 10,
        borderRadius: 5,
    },
    modalButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    buttonDisabled: {
        backgroundColor: '#ccc',
    },
    buttonDisabledText: {
        color: '#999',
    },
});

export default Friends;
