import Bodoni72Smallcaps from "../assets/fonts/Bodoni 72 Smallcaps Book.ttf";
import { StyleSheet, Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1440;
const isLargeDesktop = width > 1440;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1,
		position: "fixed",
		backdropFilter: "blur(10px)", // Apply blur effect
	},
	section: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		minHeight: "100vh",
		maxWidth: "100vw",
		zIndex: 1,
		justifyContent: "center",
		alignContent: "center",
		paddingBottom: 125,
	},
	headerBar: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		paddingHorizontal: isMobile ? 0 : isTablet ? 50 : isSmallDesktop ? 100 : 150,
		paddingTop: isMobile ? 15 : isTablet ? 30 : isSmallDesktop ? 50 : 70,
		position: "relative",
		minWidth: "100vw",
		zIndex: 98,
	},
	logoArea: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		maxHeight: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		height: 62,
		marginTop: 10,
	},
	logoText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isMobile ? 18 : isTablet ? 25 : isSmallDesktop ? 40 : 50,
		marginLeft: isMobile ? 25 : isTablet ? 0 : isSmallDesktop ? 0 : 0,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	loginArea: {
		marginRight: isMobile ? 250 : isTablet ? 250 : isSmallDesktop ? 0 : 0,
		flex: 1,
		flexDirection: "row",
		backgroundColor: "transparent",
		maxHeight: 50,
	},
	logoutButton: {
		width: 208,
		height: 62,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		zIndex: 99,
		marginLeft: isMobile ? 15 : isTablet ? 180 : isSmallDesktop ? 350 : 650,
		top: isMobile ? -10 : isTablet ? 0 : isSmallDesktop ? 0 : 0,
	},
	loginButton: {
		width: 208,
		height: 62,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		zIndex: 99,
		marginLeft: isMobile ? 15 : isTablet ? 180 : isSmallDesktop ? 350 : 650,
		top: isMobile ? -10 : isTablet ? 0 : isSmallDesktop ? 0 : 0,
	},
	loginButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	cancelButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 25,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
		paddingRight: 90,
	},
	signOutButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 28 : 25,
		textAlign: "center",
		color: "red",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},

	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isMobile ? 20 : isTablet ? 22 : isSmallDesktop ? 30 : 30,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	backgroundImage: {
		flex: 1,
		resizeMode: "cover",
	},
	welcomeArea: {
		marginTop: isMobile ? -125 : isTablet ? 100 : isSmallDesktop ? 40 : 40,
		width: "100vw",
		justifyContent: "center",
		alignItems: "center",
	},
	descriptionArea: {
		width: "100%",
		height: "100%",
		backgroundColor: "transparent",
		paddingHorizontal: isMobile ? 0 : isTablet ? 50 : isSmallDesktop ? 100 : 142,
		paddingBottom: isMobile ? 0 : isTablet ? 25 : isSmallDesktop ? 25 : 150,
	},
	welcomeText: {
		width: "75vw",
		minHeight: 100,
		height: "auto",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 45 : isTablet ? 75 : isSmallDesktop ? 100 : 128,
		marginTop: isMobile ? 150 : isTablet ? 0 : isSmallDesktop ? 0 : 50,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 5, height: 3 },
		textShadowRadius: 4,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		alignSelf: "center",
	},

	descriptionText: {
		width: isMobile ? "80vw" : isTablet ? 500 : isSmallDesktop ? "50vw" : "50vw",
		minHeight: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 100 : 100,
		height: "auto",
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isMobile ? 20 : isTablet ? 36 : isSmallDesktop ? 46 : 54,
		lineHeight: isMobile ? 35 : isTablet ? 45 : isSmallDesktop ? 60 : 75,
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textAlign: "center",
		alignSelf: "center",
		paddingTop: isMobile ? 10 : isTablet ? 10 : isSmallDesktop ? 25 : 20,
	},

	buttonsContainer: {
		flexDirection: isMobile ? "column" : "row",
		maxWidth: isMobile ? 400 : isTablet ? 800 : isSmallDesktop ? 1350 : 1500,
		alignSelf: "center", // Add this for vertical centering
		marginTop: isMobile ? 100 : isTablet ? 50 : isSmallDesktop ? -25 : -100,
		flex: 1,
	},

	buttonArea: {
		marginHorizontal: isMobile ? 50 : isTablet ? 50 : isSmallDesktop ? 50 : 50,
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
		alignSelf: "center", // Add this for vertical centering
		zIndex: 1000,
	},

	button: {
		width: isMobile ? 284 : isTablet ? 284 : isSmallDesktop ? 300 : 300,
		height: isMobile ? 55 : isTablet ? 55 : isSmallDesktop ? 60 : 60,
		marginBottom: isMobile ? 20 : 0,
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
	},

	buttonDescription: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 250 : 300,
		height: 96,
		paddingTop: 15,
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize: isMobile ? 18 : isTablet ? 18 : isSmallDesktop ? 20 : 20,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde1",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		alignSelf: "center", // Add this for vertical centering
	},

	// Styles for the custom modal
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: 22,
	},
	modalView: {
		padding: 20,
		backgroundColor: "#fefde2", // Consider using a color that matches your app's theme
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		margin: 20, // Ensures modal doesn't stretch to the edges of the screen
		alignItems: "center", // Centers the modal's content
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	buttonOpen: {
		backgroundColor: "#4c956c",
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		marginTop: 15,
	},
	buttonClose: {
		backgroundColor: "red",
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		marginTop: 15,
	},
	textStyle: {
		fontFamily: "Orpheus Pro",
		fontSize: 16,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
	modalText: {
		fontFamily: "Orpheus Pro",
		fontSize: 20,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
	buttonGreen: {
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		marginTop: 10,
		width: "80%", // Adjusts button width to match modal's width
		alignItems: "center", // Ensures text is centered within the button
	},
	buttonRed: {
		backgroundColor: "red",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		marginTop: 10,
		width: "80%", // Adjusts button width to match modal's width
		alignItems: "center", // Ensures text is centered within the button
	},
	overlayBlur: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		// backgroundColor: "rgba(0,0,0,0.7)", // translucent black
		backdropFilter: "blur(25px)", // Adjust the px value to control the amount of blur
		zIndex: 1,
	},
	blurredContainer: {
		// Apply a blur effect
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
		zIndex: 9999,
	},
	ribbonContainer: {
		position: 'absolute',
		bottom: isMobile ? 75 : isSmallDesktop ? 150 : 170, // Adjust based on your layout
		right: isMobile ? 220 : isTablet ? 40 : isSmallDesktop ? 40 : 40, // Adjust based on your layout
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 200, // Ensure it's above other elements
		backgroundColor: "#4c956c",
		padding: 10,
		borderRadius: 10,
		zIndex: 9999, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbon1: {
		position: 'absolute',
		bottom: '-100%', // Initially hidden
		transition: 'bottom 0.5s ease-out', // Smooth transition for sliding effect
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbon2: {
		position: 'absolute',
		bottom: '-200%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbon3: {
		position: 'absolute',
		bottom: '-300%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbon4: {
		position: 'absolute',
		bottom: '-300%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbon5: {
		position: 'absolute',
		bottom: '-300%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbon6: {
		position: 'absolute',
		bottom: '-300%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: 175,
		alignItems: "center",
	},
	ribbonText: {
		color: '#FFFFFF', // White text
		fontWeight: 'bold',
	},
});

export default styles;
