import React from "react";
import { View, Text, ScrollView, StyleSheet, Platform } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	

const Privacy = () => {
	// URL to your PDF file - you might need to adjust this
	// For local files, ensure the file is accessible from the public folder in your web project

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

 const pdfFileUrl = `${SERVER_URL}/pdfs/Privacy Agreement.pdf`;

	console.log(pdfFileUrl)

	return (
		<View style={styles.body}>
			{/* <View style={styles.container}> */}
				{/* <Text style={styles.headerText}>Privacy Policy</Text> */}

				{/* <ScrollView style={{ flex: 1 }}> */}
					{/* <View style={styles.pdfContainer}> */}
						{Platform.OS === "web" ? (
							<iframe src={pdfFileUrl} style={styles.pdf} frameBorder="0">
								Your browser does not support iframes.
							</iframe>
						) : (
							<Text style={styles.text}>PDF display is not supported on this platform.</Text>
						)}
					{/* </View> */}
				{/* </ScrollView> */}
			{/* </View> */}
		</View>
	);
};

// Styles
const styles = StyleSheet.create({
	body: {
		flex: 1,
		// padding: 50,
		alignItems: "center",
		backgroundColor: "#4c956c",
  height: "100vh"
	},
	container: {
		flex: 1,
		padding: 50,
		backgroundColor: "#fefde2",
		width: "95vw",
		borderRadius: 75,
	},
	headerText: {
		fontFamily: "Bodoni72Smallcaps",
		fontWeight: "500",
		fontSize: 40,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginBottom: 50,
	},
	pdfContainer: {
		flex: 1,
		width: "90vw", // Adjust as needed
		height: "100%", // Specify a fixed height or adjust as needed
		borderWidth: 1,
		borderColor: "#fff",
		borderRadius: 8,
		overflow: "hidden",
	},
	pdf: {
		width: "100%",
		height: "100vh",
		minHeight: 500,
	},
	text: {
		fontFamily: "Bodoni72Smallcaps",
		textAlign: "center",
		margin: 20,
	},
});

export default Privacy;
